import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { motion } from 'framer-motion'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { Fragment, FunctionComponent } from 'react'
import styled from 'styled-components'
import useWhatsappNumber from '@/helpers/useWhatsappNumber'
import Text from '@/style/components/LegacyText'
import { UnderlinedLink } from '@/style/components/Link'

interface NotFoundResultProps {
  searchedItem: string
}

const NotFoundItem = styled(motion.div)`
  text-align: center;
  margin: auto;
  font-size: ${({ theme }) => theme.fonts.sizes.md.fontSize * 0.875}rem;
  top: ${({ theme }) => 6.7 * 16 + theme.sizes.subHeaderHeight + 3}px;
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  margin-top: 1.4rem;
  background-color: white;
  width: 90%;
  min-width: 15rem;
  max-height: 12rem;
  line-height: 18pt;
`

const IndividualRequest = styled.div`
  text-align: center;
  margin: auto;
  padding: auto;
  padding-top: 0.7rem;
  border: 0.15rem solid #dfe1e5;
  border-radius: 0.6rem;
  width: 90%;
  max-width: 20rem;
  height: 3.5rem;
  font-weight: bolder;

  &:hover {
    cursor: pointer;
  }
`
const OderSeparator = styled.div`
  margin: auto;
  width: 90%;
  max-width: 20rem;
  max-height: 2.5rem;
`
const OderText = styled.span`
  vertical-align: center;
  background-color: white;
  font-weight: 500;
  font-size: 0.8rem;
  font-family: 'GalanoGrotesque';
`

const ContactUsDiv = styled.div`
  margin-top: 0.7rem;
  margin-left: 1.2rem;
`
const WhatsAppIconDiv = styled.div`
  display: inline;
  float: center;
  height: 0.1rem;
  margin-left: -1.6rem;
`
const WhatsAppTextDiv = styled.div`
  display: inline;

  float: center;
  height: 0.1rem;
`
const WhatsAppLink = styled.a`
  color: black;
  text-decoration: none;
`

const handleIndividualRequest = () => {}

const NotFoundResult: FunctionComponent<NotFoundResultProps> = ({
  searchedItem,
}) => {
  const { t, lang } = useTranslation()
  const whatsappNumber = useWhatsappNumber()

  return (
    <Fragment>
      <NotFoundItem>
        <Trans
          i18nKey="common:not_found_items.first_section"
          values={{ item: searchedItem }}
          components={{
            br: <br />,
            Md: <Text.md />,
            Bold: <Text.md weight="semibold" />,
          }}
        />
      </NotFoundItem>

      <NotFoundItem>
        <Link href="/customDeal" passHref>
          <IndividualRequest onClick={handleIndividualRequest}>
            <Text.md weight="semibold">
              {t('common:not_found_items.direct_request')}
            </Text.md>
          </IndividualRequest>
        </Link>

        <OderSeparator>
          <OderText>
            <Text.spacer>{t('common:not_found_items.or')}</Text.spacer>
          </OderText>
        </OderSeparator>
      </NotFoundItem>

      <NotFoundItem>
        <Text.lg>{t('common:not_found_items.contact_us_via')}</Text.lg>
        <br />
        <ContactUsDiv>
          <WhatsAppIconDiv>
            <WhatsAppIcon style={{ marginBottom: '-0.4rem' }} />
          </WhatsAppIconDiv>
          <WhatsAppTextDiv>
            <WhatsAppLink
              href={`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${t(
                'common:whatsapp.initial_message',
              )}&lang=${lang}`}
              target="_blank"
              rel="noreferrer"
            >
              <UnderlinedLink as="span">
                <Text.lg weight="semibold">{'WhatsApp'}</Text.lg>
              </UnderlinedLink>
            </WhatsAppLink>
          </WhatsAppTextDiv>
        </ContactUsDiv>
        <br />
        <br />
      </NotFoundItem>
    </Fragment>
  )
}

export default NotFoundResult
