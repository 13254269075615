import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useCallback, useState } from 'react'
// importing FunctionComponent
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import { searchClient } from '@/app/common/algolia/client'
import tracking from '@/helpers/tracking'
import { AnimatePresence } from '@/utils/animatePresence'
import Autocomplete from './Autocomplete'

interface SearchBarProps {
  isMobile: boolean
  isSearchActive: boolean
  onSearchStatusChange: (value: boolean) => void
}

const SearchBar: FunctionComponent<SearchBarProps> = (
  props: SearchBarProps,
) => {
  const { isMobile, isSearchActive } = props
  const onSearchStatusChange = props.onSearchStatusChange
  const handleSearchStatusChange = useCallback(
    (active: boolean) => {
      onSearchStatusChange(active)

      if (active) {
        tracking.trackEvent('open_search')
      }
    },
    [onSearchStatusChange],
  )

  const router = useRouter()
  const [loadingPath, setLoadingPath] = useState<string | undefined>(undefined)
  const isCheckout = router.pathname.includes('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  if (router.asPath !== loadingPath && typeof loadingPath !== 'undefined') {
    setLoadingPath(undefined)
  }

  if (isCheckout || isFinalizeAccountSetup) {
    return null
  }

  return (
    <InstantSearch indexName="products" searchClient={searchClient}>
      <Configure hitsPerPage={30} filters="NOT isInactive=1 AND hasSlug=1" />
      <AnimatePresence>
        <motion.div
          animate={{
            width: isMobile ? '100%' : isSearchActive ? '500px' : '300px',
          }}
          transition={{ delay: 0.1 }}
        >
          <Autocomplete
            isSearchActive={isSearchActive}
            onResultClick={(path) => {
              setLoadingPath(path)
              handleSearchStatusChange(false)
            }}
            handleSearchStatusChange={handleSearchStatusChange}
          />
        </motion.div>
      </AnimatePresence>
    </InstantSearch>
  )
}
export default SearchBar
