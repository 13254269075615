import { createGlobalStyle } from 'styled-components'
import reset from './reset'

export default createGlobalStyle`
    ${reset}

    * {
        box-sizing: border-box;
    }

    .unzerSandboxNotify {
        display: none; // Prevent show unwanted notification in dev environment
    }
`
