import { useRegisterCustomer } from '../../auth.service'
import AuthDialog from '../AuthDialog/AuthDialog'
import Login from '../Login/Login'
import LoginPrompt from '../LoginPrompt/LoginPrompt'
import { RegistrationSuccess } from '../RegistrationSuccess'
import { Box } from '@material-ui/core'
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import tracking from '@/helpers/tracking'
import Text from '@/style/components/LegacyText'
import media from '@/style/helpers/media'
import { parseApolloErrorPretty } from '@/utils/error'
import { RegistrationForm } from './RegistrationForm'

const FormWrapper = styled.div`
  margin-top: 0;
  text-align: center;

  ${media.sm} {
    margin-top: 20px;
  }

  ${media.md} {
    margin-top: 100px;
  }
`

export interface RegistrationModel {
  firstname: string
  lastname: string
  phone: string
  email: string
  password: string
  passwordRepeat: string
  dateOfBirth: Date | null
  aquiredBy?: string | null
}

export interface RegisterProps {
  afterRegisterAction?: (customerId: any) => void
  barTitle?: string
  headline?: string | JSX.Element
  description?: string
  success?: {
    barTitle?: string
    title?: string
    description?: string
  }
  closeAfterSuccess?: boolean
}

const Register: NextPage<RegisterProps> = (props) => {
  const { description, headline } = props

  const modalDialog = useContext(ModalDialogContext)
  const [registrationStep, setRegistrationStep] = useState<'first' | 'success'>(
    'first',
  )
  const [barTitle] = useState(props.barTitle)

  const { t } = useTranslation()

  const [registrationState] = useState<RegistrationModel>({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',
    passwordRepeat: '',
    dateOfBirth: null,
  })

  const { registerCustomer, error } = useRegisterCustomer()

  const onRegisterSubmit = async (values: RegistrationModel) => {
    const res = await registerCustomer({
      variables: values,
    }).catch(() => {})

    if (res && res.data) {
      tracking.trackEvent('registration')

      if (props.closeAfterSuccess) {
        modalDialog.close()
      } else {
        setRegistrationStep('success')
      }
      if (props.afterRegisterAction)
        props.afterRegisterAction(res.data.registerCustomer._id)
    }
  }

  const onRegisterSuccess = () => {
    modalDialog.close()
  }

  let gqlErrorPretty:
    | {
        message: string
        code: string
      }
    | undefined

  if (error) {
    gqlErrorPretty = parseApolloErrorPretty(error, t)
  }

  return (
    <AuthDialog
      navBar={{
        title: barTitle ?? t('common:auth.create_account'),
        link: {
          description: t('common:auth.account_already'),
          text: t('common:auth.login'),
          onClick: () =>
            modalDialog.open(<Login register={<Register {...props} />} />, {
              variant: 'full',
            }),
        },
      }}
      content={
        <FormWrapper>
          {registrationStep != 'success' && (
            <>
              <Text.md
                weight="semibold"
                style={{
                  fontSize: '1.375rem',
                  marginBottom: '0.75rem',
                  display: 'inline-block',
                }}
                uppercase
              >
                {headline}
              </Text.md>
              <Box textAlign="center" marginBottom="0.75rem">
                {description && (
                  <Text.md style={{ fontSize: '0.875rem' }}>
                    {description}
                  </Text.md>
                )}
              </Box>
              <LoginPrompt
                content={
                  <Login
                    register={<Register {...props} />}
                    afterLoginAction={props.afterRegisterAction}
                  />
                }
              />
            </>
          )}
          {
            {
              first: (
                <RegistrationForm
                  initialState={registrationState}
                  gqlError={gqlErrorPretty}
                  onSubmit={(values) => {
                    onRegisterSubmit(values)
                  }}
                />
              ),
              success: (
                <RegistrationSuccess
                  title={t('common:auth.account_created')}
                  description={t('common:auth.account_created_description')}
                  onClickReturn={onRegisterSuccess}
                  buttonLabel={t('common:auth.back_to_cashy')}
                />
              ),
            }[registrationStep]
          }
        </FormWrapper>
      }
    />
  )
}

export default Register
