import CartIcon from '../../../assets/ico_desktop_cart.svg'
import { ClientOnly } from '../../ClientOnly'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'

interface CartButtonProps {
  itemsCount: number
}

const CartButton: FunctionComponent<CartButtonProps> = ({ itemsCount }) => {
  return (
    <Link href="/checkout/items" passHref>
      <CartWrapper>
        <CartIconWrapper as="a" id="header_cart">
          <CartIconStyled />
          <CartIconText length={itemsCount.toString().length}>
            <ClientOnly>{itemsCount}</ClientOnly>
          </CartIconText>
        </CartIconWrapper>
      </CartWrapper>
    </Link>
  )
}

export default CartButton

const CartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
`

const CartIconWrapper = styled.div`
  display: flex;
  position: relative;
  width: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;
  height: ${({ theme }) => (theme.sizes.headerHeight / 2) * 1.2}px;
`

const CartIconStyled = styled(CartIcon)`
  height: 100%;
`

const CartIconText = styled(Text.sm)<{ length: number }>`
  font-size: 0.85rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 0.1rem;
  right: ${({ length }) => (length > 1 ? '0.75rem' : '0.95rem')};
`
