import { CustomVehicleRequestData } from '../CustomVehicleRequest'
import { AutoDocumentLinks } from '../TermsAndSubmit/TermsAndSubmit'
import { VehicleCalculationBox } from '../VehicleCalculationBox/VehicleCalculationBox'
import {
  CarCalculation,
  CarProperty,
  CustomVehicleFormData,
  OnAfterDealBookedArgs,
  OnBeforeDealBookedArgs,
  definedCarPropertyKeys,
  getVehicleCategorieId,
  odometerMaxNumber,
} from '../VehicleDealBooking'
import * as Sentry from '@sentry/nextjs'
import useTranslation from 'next-translate/useTranslation'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useRecoilState } from 'recoil'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { useLazyQuery, useMutation } from '@/app/common/graphql/hooks'
import useEnsureCustomerLoggedIn from '@/domains/car/hooks/useEnsureCustomerLoggedIn'
import { guestEmailState } from '@/domains/checkout/checkout.state'
import { MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING } from '@/helpers/constants'
import { getTotalGrossFeeAmountOfType } from '@/helpers/feesCalculation'
import { useDebouncedCallback } from '@/helpers/useDebouncedCallback'
import useShowSnackbar from '@/helpers/useShowSnackbar'
import { EVehicleCategory } from '@/types'
import {
  CarDealCalculation,
  CarDealCalculationDocument,
  CarDealCalculationQuery,
  CarDealCalculationQueryVariables,
  Company,
  CreateCarDealDocument,
  CreateCarDealMutation,
  CreateCarDealMutationVariables,
  DealItemCreateArgs,
  EManualPaymentType,
  ItemAnswerArgs,
  ItemQuestion,
  VehiclePropertiesArgs,
} from '@/types/gql/graphql'
import { getStringBetween, printLocalAmount } from '@/utils/misc'

interface CarCalculationWithValuationProps {
  pawnTimeOptionsInMonths: number[]
  formikProps: UseFormReturnType<CustomVehicleFormData>
  carPriceLastFetched?: Date
  setCarPriceLastFetched: (date: Date) => void
  company?: Pick<Company, '_id'>
  lastStepHref?: string
  allAnswersUpdatedArgs: ItemAnswerArgs[]
  baseVehicleCategoryId: EVehicleCategory
  carStillUsingQuestion: Omit<ItemQuestion, 'validTo' | 'validFrom'> | undefined
  vehicleProperties: CarProperty[]
  vehicleCategoryId: string
  documentLinks: AutoDocumentLinks
  setIsNoValuationCar: (value: boolean) => void
  activeCustomRequestForm: () => void
  onBeforeDealBooked?: (args: OnBeforeDealBookedArgs) => void | Promise<void>
  onAfterDealBooked?: (args: OnAfterDealBookedArgs) => void | Promise<void>
}

export const CarCalculationWithValuation: FunctionComponent<
  CarCalculationWithValuationProps
> = ({
  pawnTimeOptionsInMonths,
  formikProps,
  carPriceLastFetched,
  setCarPriceLastFetched,
  company,
  lastStepHref,
  allAnswersUpdatedArgs,
  carStillUsingQuestion,
  baseVehicleCategoryId,
  vehicleProperties,
  vehicleCategoryId,
  documentLinks,
  setIsNoValuationCar,
  onBeforeDealBooked,
  onAfterDealBooked,
  activeCustomRequestForm,
}) => {
  const { t } = useTranslation()
  const { showError } = useShowSnackbar()
  const [guestEmail] = useRecoilState(guestEmailState)
  const { pawnContinueUsing, odometer, pawnTime, payoutAmount } =
    formikProps.values

  const [valuationsLoading, setValuationsLoading] = useState(false)
  const [overwrittenPayoutAmount, setOverwrittenPayoutAmount] =
    useState<number>(0)

  const [createDeal, createDealResult] = useMutation<
    CreateCarDealMutation,
    CreateCarDealMutationVariables
  >(CreateCarDealDocument)

  const useDealCalculationData = () => {
    return useLazyQuery<
      CarDealCalculationQuery,
      CarDealCalculationQueryVariables
    >(CarDealCalculationDocument, {
      onError() {
        showError(t('common:errors.deal_calculation_bad_request'))
      },
      onCompleted(data) {
        if (!data) {
          return
        }
        setCarPriceLastFetched(new Date())
      },
    })
  }

  const [fetchCarStillUsingValuation, carStillUsingRes] =
    useDealCalculationData()
  const [fetchCarStoredValuation, carStoragedRes] = useDealCalculationData()
  const [fetchCarDesiredValuation, carDesiredRes] = useDealCalculationData()

  const getCarDealCalculationVariables = useCallback(
    (
      isStillUsing: boolean,
      overwrittenPayoutAmount?: number,
    ): CarDealCalculationQueryVariables | null => {
      if (!company || !lastStepHref) {
        return null
      }

      return {
        args: {
          companyId: company._id,
          href: lastStepHref,
          durationInDays: pawnTime * 30,
          odometer,
          itemCategoryId: getVehicleCategorieId(
            baseVehicleCategoryId,
            isStillUsing,
          ),
          answers: allAnswersUpdatedArgs.map((answer) =>
            answer.questionId === carStillUsingQuestion?._id
              ? {
                  ...answer,
                  selectedOptionIndex: isStillUsing ? 0 : 1,
                }
              : answer,
          ),
          ...(typeof overwrittenPayoutAmount === 'number' && {
            shouldOverwritePayoutAmount: true,
            overwrittenPayoutAmount,
          }),
        },
      }
    },
    [
      company,
      lastStepHref,
      pawnTime,
      odometer,
      baseVehicleCategoryId,
      allAnswersUpdatedArgs,
      carStillUsingQuestion?._id,
    ],
  )

  const [refetchCarValuations] = useDebouncedCallback(
    (
      getVariables: typeof getCarDealCalculationVariables,
      overwrittenPayoutAmount?: number,
    ) => {
      setValuationsLoading(false)

      const stillUsingVariables = getVariables(true, overwrittenPayoutAmount)
      const storedVariables = getVariables(false, overwrittenPayoutAmount)

      // this should never happen, as before passing getCarDealCalculationVariables
      // we check it to produce non-null values
      if (!stillUsingVariables || !storedVariables) {
        throw new Error('Failed to get variables for car deal calculation.')
      }

      fetchCarStillUsingValuation({ variables: stillUsingVariables })
      fetchCarStoredValuation({ variables: storedVariables })
    },
    500,
  )

  const dealCalculationRes = pawnContinueUsing
    ? carStillUsingRes
    : carStoragedRes
  const dealCalculation = dealCalculationRes.data
    ?.carDealCalculation as CarDealCalculation
  const desiredCalculation = carDesiredRes.data?.carDealCalculation

  useEffect(() => {
    const variables = getCarDealCalculationVariables(false)
    if (variables && odometer <= odometerMaxNumber) {
      setValuationsLoading(true)
      refetchCarValuations(getCarDealCalculationVariables)
    } else {
      setValuationsLoading(false)
      refetchCarValuations.cancel()
    }
  }, [
    refetchCarValuations,
    getCarDealCalculationVariables,
    odometer,
    lastStepHref,
  ])

  const [prevPawnContinueUsing, setPrevPawnContinueUsing] =
    useState(pawnContinueUsing)

  if (pawnContinueUsing !== prevPawnContinueUsing) {
    setPrevPawnContinueUsing(pawnContinueUsing)
    setOverwrittenPayoutAmount(0)
  }

  const [prevPawnTime, setPrevPawnTime] = useState(pawnTime)

  if (pawnTime !== prevPawnTime) {
    setPrevPawnTime(pawnTime)
    setOverwrittenPayoutAmount(0)
  }

  const onChangeOverridePayoutAmountValuation = (
    overwrittenPayoutAmount: number,
  ) => {
    setOverwrittenPayoutAmount(overwrittenPayoutAmount)

    if (!company || !lastStepHref || !overwrittenPayoutAmount) return

    fetchCarDesiredValuation({
      variables: {
        args: {
          companyId: company._id,
          href: lastStepHref,
          durationInDays: pawnTime * 30,
          odometer,
          itemCategoryId: getVehicleCategorieId(
            baseVehicleCategoryId,
            pawnContinueUsing,
          ),
          answers: allAnswersUpdatedArgs.map((answer) =>
            answer.questionId === carStillUsingQuestion?._id
              ? {
                  ...answer,
                  selectedOptionIndex: pawnContinueUsing ? 0 : 1,
                }
              : answer,
          ),
          shouldOverwritePayoutAmount: true,
          overwrittenPayoutAmount,
        },
      },
    })
  }

  useEffect(() => {
    if (
      dealCalculation?.competitiveVehiclesForSale !== null &&
      dealCalculation?.competitiveVehiclesForSale !== undefined &&
      dealCalculation.competitiveVehiclesForSale < 3
    ) {
      activeCustomRequestForm()
    } else if (dealCalculation?.noValuation) {
      setIsNoValuationCar(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealCalculation])

  const carStillUsingPayoutAmount = Math.min(
    carStillUsingRes.data?.carDealCalculation.dealValuesEntry.payoutAmount ?? 0,
    MAX_PAYOUT_AMOUNT_PAWN_CONTINUE_USING,
  )

  const carStoragedPayoutAmount =
    carStoragedRes.data?.carDealCalculation.dealValuesEntry.payoutAmount

  const feeDifferenceWhenHandingOver =
    carStillUsingPayoutAmount && carStoragedPayoutAmount
      ? carStoragedPayoutAmount - carStillUsingPayoutAmount
      : undefined

  const calculation: CarCalculation | undefined =
    !dealCalculationRes.isFetching && dealCalculation && lastStepHref
      ? {
          calculation: dealCalculation,
          desiredCalculation,
          payoutAmount: dealCalculation.dealValuesEntry.payoutAmount,
          paybackAmount: dealCalculation.dealValuesEntry.paybackAmount,
          feesCaclulation: getTotalGrossFeeAmountOfType(
            dealCalculation.appliedUsedFeeDefinitions,
          ),
          appliedUsedFeeDefinitions: dealCalculation.appliedUsedFeeDefinitions,
          dealItems: [
            {
              title: vehicleProperties
                .map((property) => property.selected?.name)
                .join(' '),
              answers: allAnswersUpdatedArgs,
              itemCategoryId: vehicleCategoryId,
            },
          ],
        }
      : undefined

  const maxPayoutAmount = dealCalculation?.dealValuesEntry.payoutAmount

  const ensureCustomerLoggedIn = useEnsureCustomerLoggedIn()

  const _onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (!formikProps.isValid) return

    const customVehicleRequestData =
      formikProps.values as CustomVehicleRequestData

    const definedVehicleProperties: Partial<VehiclePropertiesArgs> & any = {
      odometer,
    }

    if (!company) {
      console.warn('Company not loaded.')
      return
    }

    // if is normal request
    if (!dealCalculation || dealCalculationRes.isFetching || !lastStepHref)
      return

    vehicleProperties.forEach((c) => {
      if (definedCarPropertyKeys.includes(c.name)) {
        definedVehicleProperties[c.name] =
          c.name === 'odometer' ? Number(c.selected?.name) : c.selected?.name
      }
    })

    const otherVehicleProperties = vehicleProperties.filter(
      (c) => !definedCarPropertyKeys.includes(c.name),
    )

    const indicataId = getStringBetween(lastStepHref, '/AT/', '/valuation')

    const dealItemsCreateArgs: DealItemCreateArgs[] = [
      {
        itemCategoryId: vehicleCategoryId,
        answers: allAnswersUpdatedArgs,
        // TODO: Ali
        // We set the adjustedMarketValue in the customPayoutAmount field.
        // Since we also have the indicataId the API knows that we should use it as adjustedMarketValue
        // A better solution for when we have the indicataId is to pass the href to API so the price can be fetched
        // one more time in the API before creating the deal. Like what we are doing in carDealCalculation() in the API.
        // But for now we can keep this line instead.
        customPayoutAmount:
          dealCalculation.itemsValuesEntries[0].adjustedMarketValue,
        title: vehicleProperties
          .slice(0, 4)
          .map((p) => p.selected?.name)
          .join(' '),
        vehicleData: {
          indicataId,
          carPriceLastFetched,
          vehicleProperties: {
            ...definedVehicleProperties,
            otherVehicleProperties:
              otherVehicleProperties.length > 0
                ? otherVehicleProperties
                    .filter((p) => p.selected)
                    .map((p) => ({
                      name: p.name,
                      value: p.selected?.name as string,
                    }))
                : undefined,
          },
        },
      },
    ]

    if (onBeforeDealBooked) {
      try {
        await onBeforeDealBooked({
          customVehicleRequestData,
          definedVehicleProperties,
          trackingType: 'Car',
          vehicleProperties,
          dealCalculation,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // TODO: move text to translations
    const customerId = await ensureCustomerLoggedIn({
      headline: t('vehicle:signup_modal.headline', {
        amount: printLocalAmount({
          number: payoutAmount,
          fractionDigits: 0,
          removeSpaces: true,
        }),
      }),
      barTitle: t('vehicle:signup_modal.barTitle'),
      description: t('vehicle:signup_modal.description', {
        vehicleName:
          baseVehicleCategoryId === EVehicleCategory.CAR
            ? 'Autos'
            : 'Motorrades',
      }),
    })

    if (customerId === null) {
      return
    }

    const res = await createDeal({
      variables: {
        companyId: company._id,
        payoutType: EManualPaymentType.Cash,
        registeredCustomerId: customerId,
        guestCustomerDataArgs: !customerId ? { email: guestEmail } : undefined,
        dealItemsCreateArgs: dealItemsCreateArgs,
        dealCalculationId:
          overwrittenPayoutAmount && desiredCalculation
            ? desiredCalculation.dealCalculationId
            : dealCalculation.dealCalculationId,
      },
    })

    if (onAfterDealBooked) {
      if (!res.data) {
        return
      }

      const bookingNumber = res.data.createCarDeal.bookingNumber
      await onAfterDealBooked({
        bookingNumber: bookingNumber,
        customVehicleRequestData,
        definedVehicleProperties,
        trackingType: 'Car',
        dealCalculation,
      })
    }
  }

  const marketValueToSmall =
    calculation?.calculation?.__typename === 'CarDealCalculation'
      ? calculation.calculation.itemsValuesEntries[0].adjustedMarketValue <
          3000 &&
        !calculation.calculation.dealValuesEntry.shouldOverwritePayoutAmount
      : false

  const calculationLoading =
    dealCalculationRes.isFetching ||
    carDesiredRes.isFetching ||
    valuationsLoading

  return (
    <VehicleCalculationBox
      calculationLoading={calculationLoading}
      marketValueToSmall={marketValueToSmall}
      calculation={calculation}
      pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
      baseVehicleCategoryId={baseVehicleCategoryId}
      createDealResult={createDealResult}
      formikProps={formikProps}
      onSubmit={_onSubmit}
      feeDifferenceWhenHandingOver={feeDifferenceWhenHandingOver}
      isCustomRequest={false}
      noValuation={false}
      onChangeOverridePayoutAmountValuation={
        onChangeOverridePayoutAmountValuation
      }
      maxPayoutAmount={maxPayoutAmount}
      documentLinks={documentLinks}
      shouldOverwrittenPayoutAmount={
        !!overwrittenPayoutAmount && overwrittenPayoutAmount !== maxPayoutAmount
      }
    />
  )
}
