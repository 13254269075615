import { useSearchBarContext } from '../SearchBarV2'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

interface SearchBoxInputProps {
  onSearchTermChange: (value: string) => void
  searchTerm: string
}

export const SearchInput: FunctionComponent<SearchBoxInputProps> = ({
  searchTerm,
  onSearchTermChange,
}) => {
  const { setIsSearchActive } = useSearchBarContext()
  const { t } = useTranslation()

  return (
    <>
      {/* This dummy form should come before the actual search input.
    The idea is that the browser will target this dummy form for autofill instead of the actual search field. */}
      <FakeForm>
        <form>
          <input
            type="password"
            name="fakepassword"
            autoComplete="new-password"
          />
        </form>
      </FakeForm>

      <SearchBoxInputDiv
        type="search"
        value={searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onSearchTermChange(event.currentTarget.value)
        }
        placeholder={t('common:header.find_your_item_short')}
        onFocus={() => {
          setIsSearchActive(true)
        }}
        onBlur={() => {
          if (!Boolean(searchTerm.length)) {
            setIsSearchActive(false)
          }
        }}
        autoComplete="off"
        autoCorrect="off"
        role="searchbox"
      />
    </>
  )
}

const SearchBoxInputDiv = styled.input`
  -webkit-appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-right: none;
  flex: 200;
  border-radius: ${({ theme }) => theme.radius.normal}px 0 0
    ${({ theme }) => theme.radius.normal}px;
  padding: 0.65rem 1rem 0.65rem;
  font-size: ${({ theme }) => theme.fonts.sizes.sm.fontSize}rem;
  line-height: normal;
  outline: none;
  font-family: inherit;
  height: 2.8rem;
  &::placeholder {
    color: #5a5b5a;
  }
`

const FakeForm = styled.div`
  display: none;
`
