import theme from '../themes/default'

const sizes = theme.breakpoints
/*
const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
      css`
        @media (max-width: ${sizes[label]}px) {
          ${css(literals, ...placeholders)};
        }
      `.join("");
    return acc;
  },
  {} as Record<
    keyof typeof sizes,
    (l: TemplateStringsArray, ...p: any[]) => string
  >,
);
*/

const media = (Object.keys(sizes) as Array<keyof typeof sizes>).reduce(
  (acc, label) => {
    acc[label] = `@media (min-width: ${sizes[label]}px)`
    return acc
  },
  {} as Record<keyof typeof sizes, string>,
)

export const mediaFromTo = (min: keyof typeof sizes, max: keyof typeof sizes) =>
  `@media (min-width: ${sizes[min]}px) and (max-width: ${sizes[max] - 1}px)`

//const media = sizes.map(size => `@media (max-width: ${size}px)`);

export default media
