import { useSectionAnchorId } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { ScaleLoader } from 'react-spinners'
import styled, { keyframes } from 'styled-components'
import { Pagination } from '@/app/common/components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { GraphQLPrefetchClient } from '@/app/common/graphql/prefetchClient'
import ObjectList, {
  ObjectListFormItem,
  ObjectListItem,
} from '@/domains/items/components/ObjectList'
import { getSizeOfSectionInner } from '@/domains/items/components/ObjectList/ObjectList'
import {
  useCategoryPageProducts,
  useGetPageQueryParam,
} from '@/domains/items/items.service'
import { createDummyProducts } from '@/domains/items/sections/ProductSelector/ProductSelector'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import tracking from '@/helpers/tracking'
import { LastFallbackImage, UploadedImage } from '@/style/components/Image'
import theme from '@/style/themes/default'
import {
  ContentCategoriesArgs,
  EOrderBy,
  GetProductsLegacyWithPaginationDocument,
  GetProductsLegacyWithPaginationQuery,
  GetProductsLegacyWithPaginationQueryVariables,
} from '@/types/gql/graphql'
import { ProductSelectorStoryblok } from '@/types/storyblok-component-types'

type Props = {
  blok: ProductSelectorStoryblok
}

export const getProductsLegacyWithPaginationQueryVariables = (args: {
  paths: string[]
  maximumProductsPerPage: number
  pageIndex: number
  hasOtherForm: boolean
}) => {
  const productsPerPage = args.hasOtherForm
    ? Number(args.maximumProductsPerPage) - 1
    : Number(args.maximumProductsPerPage)

  const contentCategories = args.paths.reduce(
    (acc: ContentCategoriesArgs, path: string, index: number) => {
      if (`lvl${index}` in acc) {
        ;(acc as any)[`lvl${index}`] = path
      }
      return acc
    },
    {
      lvl0: '',
      lvl1: '',
      lvl2: '',
      lvl3: '',
    },
  )

  const skip = Math.max(0, args.pageIndex - 1) * productsPerPage

  return {
    opts: {
      filter: {
        contentCategories,
        excludeInactiveProducts: true,
      },
      take: productsPerPage,
      order: { title: EOrderBy.Asc },
      skip,
    },
  }
}

export function prefetchProductSelector(
  client: GraphQLPrefetchClient,
  variables: GetProductsLegacyWithPaginationQueryVariables,
) {
  return client.executeQuery<
    GetProductsLegacyWithPaginationQuery,
    GetProductsLegacyWithPaginationQueryVariables
  >({
    document: GetProductsLegacyWithPaginationDocument,
    variables,
  })
}

const ProductSelector: FunctionComponent<Props> = ({ blok }) => {
  const page = useGetPageQueryParam()
  const { t } = useTranslation()
  const router = useRouter()

  const maximumProductsPerPage = Number(blok.maximum_products_per_page)

  const variables = useMemo(
    () =>
      getProductsLegacyWithPaginationQueryVariables({
        paths: blok.product_id.split(','),
        maximumProductsPerPage,
        pageIndex: page,
        hasOtherForm: !!getStoryBlokLink(blok.other_form_link),
      }),
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blok],
  )

  const dummyProducts = useMemo(
    () => createDummyProducts(Number(blok.maximum_products_per_page)),
    [blok],
  )

  const {
    productsQueryResult,
    numberOfProductsPages,
    pageNumber: pageNumberProducts,
  } = useCategoryPageProducts(variables)

  const productsWithPagination =
    productsQueryResult.data?.getProductsLegacyWithPagination
  const isLoading = productsQueryResult.isFetching

  useEffect(() => {
    if (!productsWithPagination) {
      return
    }

    tracking.viewItemList(productsWithPagination.products, {
      item_list_id: 'product_selector',
    })
  }, [productsWithPagination])

  const otherForm = useMemo(
    () =>
      getStoryBlokLink(blok.other_form_link) && {
        title: t('common:found_nothing_appropriate'),
        prompt: t('common:contact_us'),
        href: getStoryBlokLink(blok.other_form_link),
      },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blok],
  )

  const products =
    !isLoading && productsWithPagination?.products
      ? productsWithPagination?.products.map((product) => {
          return {
            ...product,
            picture: product.picture,
          }
        })
      : dummyProducts

  const sectionAnchorId = useSectionAnchorId()
  const handleChangePage = useCallback((nextPage: number) => {
    router.push({
      query: { ...router.query, page: nextPage },
      hash: `#${sectionAnchorId}`,
    })
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ProductSelectorWrapper
        sizing={getSizeOfSectionInner(products.length + (otherForm ? 1 : 0))}
        {...storyblokEditable(blok)}
      >
        {isLoading && (
          <InlineLoader>
            <ScaleLoader color={theme.colors.primary} loading={true} />
          </InlineLoader>
        )}
        <ObjectList
          hideElements={!!isLoading}
          numberOfItems={products.length + (otherForm ? 1 : 0)}
        >
          {products.map((product) => (
            <ObjectListItem
              key={product._id}
              title={product.title}
              href={{ pathname: '/item/[slug]', query: { slug: product.slug } }}
              ribbon={false}
              Image={
                product.picture ? (
                  <UploadedImage
                    data={product.picture}
                    subset={['product_small', 'product_thumbnail']}
                    sizes={{
                      md: 'product_small',
                    }}
                    primarySize="product_small"
                  />
                ) : blok.fallback_image?.id ? (
                  <StoryblokImage
                    image={blok.fallback_image}
                    layout="responsive"
                    objectFit="cover"
                  />
                ) : (
                  <LastFallbackImage />
                )
              }
            />
          ))}
          {otherForm && !isLoading && <ObjectListFormItem {...otherForm} />}
        </ObjectList>
        {!isLoading && (
          <Pagination
            variant="outlined"
            shape="rounded"
            siblingCount={0}
            count={numberOfProductsPages}
            page={pageNumberProducts}
            onChange={(e, v) => handleChangePage(v)}
          />
        )}
      </ProductSelectorWrapper>
    </>
  )
}

const fadeIn = keyframes`
  0% { opacity:0; }
  50% { opacity:0; }
  100% { opacity:1; }
`

const InlineLoader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 3s linear;
`

const ProductSelectorWrapper = styled.div.attrs<{
  sizing: 'wide' | 'near-wide'
}>((props) => ({
  sizing: props.sizing,
}))<{ sizing: 'wide' | 'near-wide' }>`
  margin: 0 auto;
  position: relative;
  max-width: ${({ sizing }) => (sizing === 'wide' ? undefined : '1200px')};
`

export default ProductSelector
