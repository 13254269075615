import { useState } from 'react'
import styled from 'styled-components'
import config from '@/config'
import { media } from '@/style/helpers'
import SearchBar from './SearchBar'
import SearchBarV2 from './SearchBarV2'

type Props = {
  isCompact: boolean
}

export const MobileSecondaryHeader = ({ isCompact }: Props) => {
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false)

  const onSearchStatusChange = (value: boolean) => {
    setIsSearchActive(value)
  }

  return (
    <MobileSearchWrapper isCompact={isCompact}>
      {config.isNewProductSearchEnabled ? (
        <SearchBarV2
          isMobile={true}
          isSearchActive={isSearchActive}
          setIsSearchActive={onSearchStatusChange}
        />
      ) : (
        <SearchBar
          isMobile={true}
          isSearchActive={isSearchActive}
          onSearchStatusChange={onSearchStatusChange}
        />
      )}
    </MobileSearchWrapper>
  )
}

const MobileSearchWrapper = styled.div<{
  isCompact: boolean
}>`
  padding: 8px;
  > div {
    width: inherit;
    border: 2px black solid;
    border-radius: 7px;
  }

  ${media.md} {
    display: none;
  }
`
