import React from 'react'
import {
  GridItem,
  Head,
  HeaderSpacer,
  HeaderSpacerCheckout,
  SecondaryHeader,
} from './styled'

type Props = {
  primaryHeader: React.ReactNode
  secondaryHeader?: React.ReactNode
  navMenu?: React.ReactNode
  isCompact?: boolean
  isCheckout?: boolean
}

export const HeaderLayout = ({
  primaryHeader,
  secondaryHeader = null,
  isCompact,
  isCheckout,
  navMenu,
}: Props) => {
  return (
    <>
      <Head>
        <GridItem area="primaryHeader" zIndex={101}>
          {primaryHeader}
        </GridItem>
        <SecondaryHeader area="secondaryHeader" zIndex={102}>
          {secondaryHeader}
        </SecondaryHeader>
      </Head>
      {navMenu}
      {isCheckout ? (
        <HeaderSpacerCheckout />
      ) : (
        <HeaderSpacer id="header-spacer" isCompact={isCompact} />
      )}
    </>
  )
}
