import DefaultLanguageIcon from '../../assets/languageSelector/vector.svg'
import styled from 'styled-components'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  MainNavigation,
} from '@/app/common/components/Header/styled'
import theme from '@/style/themes/default'
import { Language } from './languages'

interface DesktopLanguageSelectorProps {
  selectedLanguage: Language | undefined
  handleLocaleChange: (locale: string) => void
  languages: Language[] | undefined
  isInFooter?: boolean
}

const DesktopLanguageSelector = ({
  selectedLanguage,
  handleLocaleChange,
  languages,
  isInFooter,
}: DesktopLanguageSelectorProps) => {
  return (
    <DropdownStyled $isFooter={isInFooter}>
      <MainNavigationStyled $isFooter={isInFooter}>
        <DefaultLanguageIcon fill={isInFooter ? '#ffffff' : '#000000'} />
        {selectedLanguage?.name}
      </MainNavigationStyled>
      <DropdownMenuStyled
        className="dropdown-list"
        position="right"
        $offset={8}
        $minWidth={150}
        $isFooter={isInFooter}
      >
        {languages?.map(({ name, locale, Flag }) => (
          <DropdownItemStyled
            key={locale}
            onClick={() => handleLocaleChange(locale)}
          >
            <IconWrapper>
              <Flag width={24} height={24} />
            </IconWrapper>

            {name}
          </DropdownItemStyled>
        ))}
      </DropdownMenuStyled>
    </DropdownStyled>
  )
}
const DropdownStyled = styled(Dropdown)<{ $isFooter?: boolean }>`
  @media (max-width: ${theme.breakpoints.md}px) {
    display: none;
  }
  height: 100%;
  margin-left: ${({ $isFooter }) => ($isFooter ? '2rem' : '0')};
`
const MainNavigationStyled = styled(MainNavigation)<{ $isFooter?: boolean }>`
  align-items: center;
  display: flex;
  column-gap: 0.5rem;
  color: ${({ $isFooter }) => ($isFooter ? '#ffffff' : '#000000')};
`

const DropdownItemStyled = styled(DropdownItem)`
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
`

const DropdownMenuStyled = styled(DropdownMenu)<{ $isFooter?: boolean }>`
  top: ${({ $isFooter }) => ($isFooter ? 'unset' : '100%')};
  bottom: ${({ $isFooter }) => ($isFooter ? '100%' : 'unset')};
`
const IconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  border-radius: 6.25rem;
  display: inline-block;

  margin-right: 8px;
`

export default DesktopLanguageSelector
