import React, { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components'
import star from './assets/star.svg'

const StarsRating: FunctionComponent<{ rating: number }> = ({ rating }) => {
  const stars = useMemo(() => {
    const starArr: number[] = []
    for (let i = 1; i <= 5; i++) {
      if (Math.round(rating) >= i) {
        starArr.push(1)
      }
    }
    return starArr
  }, [rating])

  return (
    <StarRatingWrapper>
      {stars.map((star, k) => (
        <StarImage key={k} />
      ))}
    </StarRatingWrapper>
  )
}

const StarRatingWrapper = styled.div`
  display: inline-block;
  margin-bottom: 0.25rem;
`

const StarImage = styled(star)`
  margin: 0 0.1em;
`

export default StarsRating
