import {
  DocumentNode,
  GraphQLError,
  OperationDefinitionNode,
  parse,
  print,
} from 'graphql'
import fetch from 'isomorphic-unfetch'
import { GraphQLOperationError } from '@/app/common/graphql/error'
import { omitDeep } from '@/utils/misc'
import { addTypenameToDocument } from './addTypenameToDocument'

export type Variables = Record<string, unknown>

type ClientInit = {
  url: string
  headers?: HeadersInit
  responseMiddleware?: ResponseMiddleware<unknown>
}

export class GraphQLCLient {
  url: string
  headers?: HeadersInit
  responseMiddleware?: ResponseMiddleware<unknown>

  constructor({ url, headers, responseMiddleware }: ClientInit) {
    this.url = url
    this.headers = headers
    this.responseMiddleware = responseMiddleware
  }

  async request<T, V extends Variables = Variables>({
    query,
    variables,
  }: Pick<RequestOptions<T, V>, 'query' | 'variables'>) {
    return makeRequest<T, V>({
      query,
      variables,
      url: this.url,
      headers: this.headers,
      responseMiddleware: this.responseMiddleware,
    })
  }
}

type FunctionOrValue<T> = T | (() => T)

type HeadersInit = FunctionOrValue<Record<string, string> | undefined>

type ResponseMiddleware<T> = (response: GraphQLOperationResult<T>) => void

type RequestOptions<T, V> = {
  query: string
  variables?: V
  url: string
  headers?: HeadersInit
  responseMiddleware?: ResponseMiddleware<T>
}

type GraphQLResponse<T> =
  | {
      data: T
      errors: Array<GraphQLError> | null
    }
  | {
      data: null
      errors: Array<GraphQLError>
    }

export type GraphQLOperationResult<T> =
  | {
      data: T
      error: null
    }
  | {
      data: null
      error: GraphQLOperationError
    }

async function makeRequest<T, V extends Variables = Variables>(
  options: RequestOptions<T, V>,
) {
  const additionalHeaders = options.headers
    ? typeof options.headers === 'function'
      ? options.headers()
      : options.headers
    : {}

  try {
    let document = parse(options.query)
    document = addTypenameToDocument(document)
    const res = await fetch(options.url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...additionalHeaders,
      },
      body: JSON.stringify({
        query: print(document),
        variables: omitTypename(options.variables),
        operationName: extractOperationName(document),
      }),
    }).then((res) => res.json() as Promise<GraphQLResponse<T>>)

    // For backwards compatibility with apollo-client, we need to emulate the
    // default error policy ('none'). This means that we should return the data
    // only if there are no errors in the response.
    const operationResponse: GraphQLOperationResult<T> =
      res.data && !res.errors
        ? {
            data: res.data,
            error: null,
          }
        : {
            data: null,
            error: new GraphQLOperationError({
              graphQLErrors: res.errors,
              networkError: null,
            }),
          }

    options?.responseMiddleware?.(operationResponse)
    return operationResponse
  } catch (e) {
    const operationResponse: GraphQLOperationResult<T> = {
      data: null,
      error: new GraphQLOperationError({
        graphQLErrors: null,
        networkError: new Error(
          'Network Error: ' + (e as Error).message ?? 'Unknown Error',
          { cause: e },
        ),
      }),
    }
    if (options.responseMiddleware) {
      options.responseMiddleware(operationResponse)
    }
    return operationResponse
  }
}

function extractOperationName(document: DocumentNode): string | null {
  const operationDefinitions = document.definitions.filter(
    (definition) => definition.kind === `OperationDefinition`,
  ) as OperationDefinitionNode[]
  return operationDefinitions?.[0]?.name?.value || null
}

function omitTypename<T>(variables: T): T {
  if (typeof variables !== 'object') {
    return variables
  }
  return omitDeep(variables, '__typename', (_obj) => {
    return ['File'].includes(_obj.constructor.name)
  })
}
