import CartButton from '../Header/CartButton'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import config from '@/config'
import AccountButton from './AccountButton'
import MenuButton from './MenuButton'
import SearchBar from './SearchBar'
import SearchBarV2 from './SearchBarV2'
import {
  Center,
  GridItem,
  PrimaryHeaderWrapperCheckout,
  TabletPrimaryHeaderWrapper,
} from './styled'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
}

export const TabletPrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
}: Props) => {
  return (
    <TabletPrimaryHeaderWrapper>
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        itemsTotalAmount={itemsTotalAmount}
      />
    </TabletPrimaryHeaderWrapper>
  )
}

export const PrimaryHeaderCheckout = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
}: Exclude<Props, 'isCheckout'>) => {
  return (
    <PrimaryHeaderWrapperCheckout>
      <PrimaryHeader
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
        itemsCount={itemsCount}
        itemsTotalAmount={itemsTotalAmount}
      />
    </PrimaryHeaderWrapperCheckout>
  )
}

const PrimaryHeader = ({ menuOpen, toggleMenu, itemsCount }: Props) => {
  const router = useRouter()
  const isCheckout = router.pathname.includes('/checkout')
  const isProfile = router.pathname.includes('/profile')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false)

  const onSearchStatusChange = (value: boolean) => {
    setIsSearchActive(value)
  }

  return (
    <>
      <GridItem area="menu">
        <Center justifyContent="center">
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </Center>
      </GridItem>

      <GridItem area="logo">
        <Center>
          <HeaderLogo />
        </Center>
      </GridItem>

      {!isProfile && (
        <GridItem area="search">
          <Center>
            {config.isNewProductSearchEnabled ? (
              <SearchBarV2
                isMobile={false}
                isSearchActive={isSearchActive}
                setIsSearchActive={onSearchStatusChange}
              />
            ) : (
              <SearchBar
                isMobile={false}
                isSearchActive={isSearchActive}
                onSearchStatusChange={onSearchStatusChange}
              />
            )}
          </Center>
        </GridItem>
      )}

      {!isCheckout && !isFinalizeAccountSetup && itemsCount > 0 && (
        <GridItem zIndex={isSearchActive ? 0 : 1} area="cart">
          <Center justifyContent="center">
            <CartButton itemsCount={itemsCount} />
          </Center>
        </GridItem>
      )}
      {!isCheckout && !isFinalizeAccountSetup && (
        <AccountButton zIndex={isSearchActive ? 0 : 1} />
      )}
      {!isCheckout && (
        <GridItem zIndex={1} area="language">
          <LanguageSelector />
        </GridItem>
      )}
    </>
  )
}
