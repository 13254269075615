import { storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { RichTextStoryblok } from '@/types/storyblok-component-types'

export default function RichText({ blok }: { blok: RichTextStoryblok }) {
  return (
    <Wrapper
      {...storyblokEditable(blok)}
      $textAlign={blok.text_align}
      $maxWidth={blok.max_width}
    >
      <RichTextParagraphStyles>
        <StoryblokRichText document={blok.content} />
      </RichTextParagraphStyles>
    </Wrapper>
  )
}

const sizeByOption = {
  wide: 920,
  normal: 720,
}

const Wrapper = styled.div<{
  $textAlign: 'left' | 'center' | 'right' | ''
  $maxWidth?: 'wide' | 'normal' | ''
}>`
  width: 100%;
  text-align: ${({ $textAlign }) => $textAlign};
  ${({ $maxWidth }) =>
    $maxWidth ? `max-width: ${sizeByOption[$maxWidth]}px;` : ''}

  ${({ $textAlign }) => {
    if ($textAlign !== 'center') {
      return ''
    }

    return `
      & ul, & ol {
        display: inline-block;
      }
    `
  }}

  & p > a {
    color: inherit;
    text-decoration-color: ${({ theme }) => theme.colors.secondary} !important;
    text-underline-offset: 3px;
    font-weight: 600 !important;
    cursor: pointer;
    border-bottom-width: 2px;
    display: inline-block;
    u {
      text-decoration: none;
    }
  }
`
