import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'

interface ConfiguratorHeadlineProps {
  image: JSX.Element
  categoryHeadline: string
  productHeadline: string
  productSubhead?: string
}

const ConfiguratorHeadline = ({
  image,
  categoryHeadline,
  productHeadline,
  productSubhead,
}: ConfiguratorHeadlineProps) => {
  const renderDesktopLayout = () => (
    <DesktopWrapper>
      <Col xs={10} md={4}>
        <DesktopImageWrapper>{image}</DesktopImageWrapper>
      </Col>
      <Col xs={12} md={7}>
        <Text.md as="h2" weight="semibold" transform="uppercase">
          {categoryHeadline}
        </Text.md>
        <Text.xl weight="bold" transform="uppercase">
          <Text.modify color="primary">{productHeadline}</Text.modify>
          <br />
          {productSubhead}
        </Text.xl>
      </Col>
    </DesktopWrapper>
  )

  const renderMobileLayout = () => (
    <MobileWrapper>
      <Text.md as="h2" weight="semibold" transform="uppercase">
        {categoryHeadline}
      </Text.md>
      <MobileImageWrapper>{image}</MobileImageWrapper>
      <Text.xxl weight="bold" transform="uppercase">
        <Text.modify color="primary">{productHeadline}</Text.modify>
        <br />
        {productSubhead}
      </Text.xxl>
    </MobileWrapper>
  )

  return (
    <>
      {renderDesktopLayout()}
      {renderMobileLayout()}
    </>
  )
}

const DesktopWrapper = styled(Row)`
  align-items: center;

  @media (max-width: ${theme.breakpoints.md}px) {
    display: none;
  }

  h2 {
    margin-bottom: 0.5rem;
  }
`

const MobileWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: ${theme.breakpoints.md}px) {
    display: none;
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    bottom: -3rem;
    left: -1.5rem;
    right: -1.5rem;
    height: 15rem;
    background-color: ${({ theme }) => theme.colors.buttonBorder};
    z-index: -1;
    border-top-left-radius: ${({ theme }) => theme.radius.semiLarge}px;
    border-top-right-radius: ${({ theme }) => theme.radius.semiLarge}px;

    ${media.xs} {
      left: -2.5rem;
      right: -2.5rem;
      border-top-left-radius: ${({ theme }) => theme.radius.large}px;
      border-top-right-radius: ${({ theme }) => theme.radius.large}px;
    }
  }

  > *:not(:last-child) {
    z-index: 1;
    margin-bottom: 1.5rem;
  }
`

const DesktopImageWrapper = styled.div`
  position: relative;
  width: 80%;
  aspect-ratio: 1;
`

const MobileImageWrapper = styled.div`
  position: relative;
  width: 50vw;
  max-width: 15rem;
  aspect-ratio: 1;
`

export default ConfiguratorHeadline
