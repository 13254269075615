import { DefaultTheme } from 'styled-components'

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
}

const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

const theme: DefaultTheme = {
  breakpoints,
  fonts: {
    primary: 'GalanoGrotesque, Fallback, Arial, sans-serif',
    fontWeights,
    sizes: {
      xs: {
        fontSize: 0.625, //rem
        fontWeight: 400,
        letterSpacing: 0.017,
        lineHeight: 1.2,
      },

      sm: {
        fontSize: 0.75, //rem
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.17,
      },

      ms: {
        fontSize: 0.875, //rem
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.17,
      },

      md: {
        fontSize: 1, //rem
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.4,
      },

      lg: {
        fontSize: 1.125, //rem
        fontWeight: 400,
        letterSpacing: 0.017,
        lineHeight: 1.23,
      },

      lgx: {
        fontSize: 1.5, //rem
        fontWeight: 700,
        letterSpacing: 0.017,
        lineHeight: 1.21,
      },

      xl: {
        fontSize: 2, //rem
        fontWeight: 400,
        letterSpacing: 0.014,
        lineHeight: 1.19,
      },

      xxl: {
        fontSize: 2.375, //rem
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight: 1.21,
      },

      xxxl: {
        fontSize: 3.125, //rem
        fontWeight: 400,
        letterSpacing: 0.023,
        lineHeight: 1.2,
      },

      x4l: {
        fontSize: 4.125, //rem
        fontWeight: 600,
        letterSpacing: 0.023,
        lineHeight: 1,
      },
    },
    typescales: {
      fatNumbers: {
        null: {
          fontSize: 2.5, //rem
          fontWeight: fontWeights.semibold,
          letterSpacing: -0.006, //em
          lineHeight: 1.1, //em
        },
        md: {
          fontSize: 4.125, //rem
        },
      },
      h1: {
        null: {
          fontSize: 2.125, //rem
          fontWeight: fontWeights.bold,
          letterSpacing: 0.007, //em
          lineHeight: 1.12, //em
        },
        md: {
          fontSize: 3.125, //rem
          letterSpacing: 0.023,
        },
      },
      h2: {
        null: {
          fontSize: 1.75, //rem
          fontWeight: fontWeights.bold,
          letterSpacing: 0,
          lineHeight: 1.07,
        },
        md: {
          fontSize: 2.375,
          lineHeight: 1.16,
          letterSpacing: 0.017,
        },
      },
      h3: {
        null: {
          fontSize: 1.625, //rem
          fontWeight: fontWeights.bold,
          letterSpacing: 0.004,
          lineHeight: 1.08,
        },
        md: {
          fontSize: 2, //rem
          lineHeight: 1.06,
          letterSpacing: 0,
        },
      },
      h4: {
        null: {
          fontSize: 1.5, //rem
          fontWeight: fontWeights.bold,
          letterSpacing: 0.005,
          lineHeight: 1.08,
        },
        md: {
          fontSize: 1.75, //rem
          lineHeight: 1.14,
          letterSpacing: 0.016,
        },
      },
      h5: {
        null: {
          fontSize: 1.25, //rem
          fontWeight: fontWeights.bold,
          letterSpacing: 0.025,
          lineHeight: 1.3,
        },
        md: {
          fontSize: 1.5, //rem
          lineHeight: 1.08,
          letterSpacing: 0.016,
        },
      },
      h6: {
        null: {
          fontSize: 1, //rem
          fontWeight: fontWeights.semibold,
          letterSpacing: 0.017,
          lineHeight: 1.125,
        },
        md: {
          fontSize: 1.125, //rem
          lineHeight: 1.24,
        },
      },
      quote: {
        null: {
          fontSize: 0.875, //rem
          fontWeight: fontWeights.medium,
          letterSpacing: 0,
          lineHeight: 1.57,
          fontStyle: 'italic',
        },
        md: {
          fontSize: 1, //rem
          lineHeight: 1.375, //em
        },
      },
      body1: {
        null: {
          fontSize: 0.875, //rem
          fontWeight: fontWeights.regular,
          letterSpacing: 0,
          lineHeight: 1.29,
        },
        md: {
          fontSize: 1, //rem
          lineHeight: 1.375, //rem
        },
      },
      body2: {
        null: {
          fontSize: 0.75, //rem
          fontWeight: fontWeights.regular,
          letterSpacing: 0,
          lineHeight: 1.17,
        },
        md: {
          fontSize: 0.875, //rem
          lineHeight: 1.43,
        },
      },
      caption: {
        null: {
          fontSize: 0.625, //rem
          fontWeight: fontWeights.regular,
          lineHeight: 1.2,
        },
        md: {
          fontSize: 0.75, //rem
          letterSpacing: 0.02,
          lineHeight: 1.17,
        },
      },
      bodySmall: {
        null: {
          fontSize: 0.625, //rem
          fontWeight: fontWeights.medium,
          letterSpacing: 0.02,
          lineHeight: 1.2,
        },
        md: {
          fontSize: 0.75, //rem
          lineHeight: 1.2,
        },
      },
      footer: {
        null: {
          fontSize: 0.625, //rem
          fontWeight: fontWeights.medium,
          letterSpacing: 0.15, //em
          lineHeight: 1.6,
        },
        md: {
          fontSize: 0.75, //rem
          letterSpacing: 0.2, //em
          lineHeight: 2,
        },
      },
    },
  },

  sections: {
    small: {
      xs: 1,
      sm: 1,
      md: 1.25,
      lg: 1.25,
    },
    normal: {
      xs: 2.5,
      sm: 2.5,
      md: 2.5,
      lg: 3.5,
    },
    large: {
      xs: 2.5,
      sm: 2.5,
      md: 3.5,
      lg: 4.5,
    },
    xlarge: {
      xs: 3.5,
      sm: 3.5,
      md: 4.5,
      lg: 6,
    },
  },

  colors: {
    primary: '#5016F4',
    primaryHover: '#f1f0fe',
    secondary: '#01FF6C',
    border: '#E9E9E9',
    subHeaderBkg: '#000000',
    subHeaderSecondaryBkg: '#545454',
    subHeaderText: '#ffffff',
    subHeaderSpacer: '#403F3F',
    white: '#ffffff',
    black: '#000000',
    gray: '#999696',
    light: '#666666',
    secondaryText: '#6E6E6E',
    buttonBorder: '#F2F4F8',
    spacerColor: '#D6D8D1',
    darkBlue: '#280787',
    violet: '#3A3A80',
    darkViolet: '#707ED8',
    lightBlue: '#5E73FF',
    lightGrey: '#dfe1e5',
    error: '#f44336',
    success: '#31C46F',
    warning: '#FBBC00',
    bgHomeHeaderPrimary: '#77915A',
    bgHomeHeaderSecondary: '#4E5E3B',
    bgCarHeaderPrimary: '#4049A9',
    bgCarHeaderSecondary: '#23285C',
  },
  sizes: {
    subHeaderHeight: 0, //px
    headerHeight: 66, //px
  },
  radius: {
    normal: 5,
    semiLarge: 10,
    large: 20,
  },
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: breakpoints.sm / 16, // em
      md: breakpoints.md / 16, // em
      lg: breakpoints.lg / 16, // em
    },
  },
}

export default theme
