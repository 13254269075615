import { LogoJsonLd } from 'next-seo'
import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'
import useRegionCode from '@/helpers/useRegionCode'
import SEOLogo from './logo.png'
import LogoImage from './logo.svg'
import { regions } from '~/next-seo.config'

type Props = {
  className?: string
}

export function HeaderLogo({ className }: Props) {
  const regionCode = useRegionCode()
  const { baseUrl } = regions[regionCode]

  return (
    <LogoContainer href="/">
      <LogoJsonLd logo={SEOLogo.src} url={baseUrl} />
      <LogoImage className={className} />
    </LogoContainer>
  )
}

const LogoContainer = styled(Link)`
  display: inline-block;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 0.5rem;
`
