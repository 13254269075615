import LoggedInAccountIcon from '../../assets/ico_desktop_account_loggedIn.svg'
import AccountIcon from '../../assets/ico_mobile_account.svg'
import { ModalDialogContext } from '../../context/modalDialogContext'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Link from '../Link/Link'
import { useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import MenuButton from './MenuButton'
import MobileCartButton from './MobileCartButton'
import {
  ActionItems,
  Center,
  CenteredMobileAction,
  GridItem,
  MobilePrimaryHeaderWrapperV2,
} from './styled'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
  isCheckout?: boolean
}

const headerPrefix = 'header'

export const MobilePrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
  isCheckout,
}: Props) => {
  const [customer] = useRecoilState(customerState)
  const modal = useContext(ModalDialogContext)

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  // for SSR
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    setIsLoggedIn(!!customer)
  }, [customer])

  return (
    <MobilePrimaryHeaderWrapperV2>
      <GridItem area="logo">
        <Center>
          <div>
            <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
          </div>
          <div>
            <HeaderLogo />
          </div>
        </Center>
      </GridItem>

      <GridItem area="cart" hidden={isCheckout}>
        <ActionItems justifyContent="flex-end">
          {itemsCount > 0 && (
            <GridItem area="sub-cart">
              <CenteredMobileAction justifyContent="center" px="0.5rem">
                <MobileCartButton
                  itemsCount={itemsCount}
                  itemsTotalAmount={itemsTotalAmount}
                  iconOnlyDisplay
                />
              </CenteredMobileAction>
            </GridItem>
          )}

          <GridItem
            id={!isLoggedIn ? `${headerPrefix}_login-or-signup` : undefined}
            area="profile"
            onClick={!isLoggedIn ? handleLoginClick : undefined}
          >
            {isLoggedIn ? (
              <Link id={`${headerPrefix}_profile`} href="/profile">
                <CenteredMobileAction justifyContent="center" px="0.5rem">
                  <LoggedInAccountIcon width={40} height={40} />
                </CenteredMobileAction>
              </Link>
            ) : (
              <CenteredMobileAction justifyContent="center" px="0.5rem">
                <AccountIcon />
              </CenteredMobileAction>
            )}
          </GridItem>
          <GridItem area="language">
            <LanguageSelector />
          </GridItem>
        </ActionItems>
      </GridItem>
    </MobilePrimaryHeaderWrapperV2>
  )
}
