import { Box } from '@material-ui/core'
import { Translate } from 'next-translate'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import * as Yup from 'yup'
import useForm from '@/app/common/components/Form/useForm'
import SuccessMessage from '@/app/common/components/SuccessMessage'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useQuery } from '@/app/common/graphql/hooks'
import tracking from '@/helpers/tracking'
import useRegionCode from '@/helpers/useRegionCode'
import { EVehicleCategory, SelectedItemsType } from '@/types'
import {
  CarDealCalculation,
  ERegionCode,
  GetItemQuestionsByCategoryDocument,
  GetItemQuestionsByCategoryQuery,
  GetItemQuestionsByCategoryQueryVariables,
  ItemAnswerArgs,
  ItemQuestion,
  UsedFeeDefinition,
  VehiclePropertiesArgs,
} from '@/types/gql/graphql'
import { dayjs } from '@/utils/time'
import CarRequest from './CarRequest/CarRequest'
import CustomVehicleRequest, {
  CustomVehicleRequestData,
} from './CustomVehicleRequest'
import { AutoDocumentLinks } from './TermsAndSubmit/TermsAndSubmit'
import VehiclePawnableQuestions from './VehiclePawnableQuestions/VehiclePawnableQuestions'

export interface CarProperty {
  name: string
  selected?: CarOption
  options: CarOption[]
}

interface CarOption {
  name: string
  href: string
}

export interface CarCalculation {
  calculation?: CarDealCalculation
  desiredCalculation?: CarDealCalculation
  payoutAmount?: number
  paybackAmount?: number
  feesCaclulation?: number
  appliedUsedFeeDefinitions?: UsedFeeDefinition[]
  dealItems?: SelectedItemsType[]
}

interface CarPageProps {
  documentLinks: AutoDocumentLinks
  onlyCustomRequest?: boolean
  vehicleCategory: EVehicleCategory
  scrollToStart?: () => void
  id?: string
}

export interface CustomVehicleFormData
  extends Omit<CustomVehicleRequestData, 'desiredPayoutAmount'> {
  desiredPayoutAmount?: number
}

export const definedCarPropertyKeys = [
  'make',
  'model',
  'regdate',
  'body',
  'engine',
  'facelift',
  'seats',
  'transmission',
  'odometer',
  'trim',
]

type TrackingTypes = 'Car' | 'CarCR' | 'MotorcycleCR'

export const odometerMaxNumber = 250000

const CarPage: React.FC<CarPageProps> = ({
  onlyCustomRequest,
  vehicleCategory: baseVehicleCategoryId,
  scrollToStart,
  documentLinks,
  id,
}) => {
  const { t } = useTranslation()
  const regionCode = useRegionCode()
  const router = useRouter()
  const modal = useContext(ModalDialogContext)

  const [isCustomRequest, setIsCustomRequest] = useState(false)
  const [pawnable, setPawnable] = useState(true)

  const carPawnFormikValidationSchema = useMemo(
    () => createValidationSchema(isCustomRequest, t),
    [isCustomRequest, t],
  )

  const pawnTimeOptionsInMonths = useMemo(
    () => (regionCode === ERegionCode.De ? [3] : [1, 2, 3]),
    [regionCode],
  )

  const carPawnFormikInitialState = useMemo(
    (): CustomVehicleFormData => ({
      model: '',
      make: '',
      regdate: '',
      odometer: 0,
      horsePower: '',
      payoutAmount: 0,
      fuel: 'diesel',
      pawnTime: pawnTimeOptionsInMonths[0],
      dataPrivacyTermsAccepted: false,
      fernAbsGAccepted: false,
      pawnContinueUsing: regionCode !== ERegionCode.De,
    }),
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionCode],
  )

  // ** FORMIK ** //
  const formikProps = useForm<CustomVehicleFormData>({
    enableReinitialize: true,
    initialState: carPawnFormikInitialState,
    validationSchema: carPawnFormikValidationSchema,
    onSubmit() {
      return
    },
  })

  const { pawnContinueUsing } = formikProps.values

  const vehicleCategoryId = useMemo(
    () => getVehicleCategorieId(baseVehicleCategoryId, pawnContinueUsing),
    [baseVehicleCategoryId, pawnContinueUsing],
  )

  const [date] = useState(dayjs().toDate())

  const { data: normalCarQuestionsData } = useQuery<
    GetItemQuestionsByCategoryQuery,
    GetItemQuestionsByCategoryQueryVariables
  >(GetItemQuestionsByCategoryDocument, {
    variables: {
      itemCategoryId: vehicleCategoryId,
      date,
      isCustomItem: true,
      omitDeactivatedQuestionsForNewProducts: false,
    },
  })

  const { data: customCarQuestionsData } = useQuery<
    GetItemQuestionsByCategoryQuery,
    GetItemQuestionsByCategoryQueryVariables
  >(GetItemQuestionsByCategoryDocument, {
    variables: {
      itemCategoryId: vehicleCategoryId,
      date,
      isCustomItem: false,
      omitDeactivatedQuestionsForNewProducts: false,
    },
  })

  const itemQuestions: Omit<ItemQuestion, 'validTo' | 'validFrom'>[] = [
    ...(normalCarQuestionsData?.getItemQuestionsByCategory ?? []),
    ...(customCarQuestionsData?.getItemQuestionsByCategory ?? []),
  ]

  // ** DEAL CALCULATION FUNCTIONS ** //
  // get question
  const carStillUsingQuestion = itemQuestions.find(isContinueUsingQuestion)

  const allAnswersUpdatedArgs = useMemo(() => {
    // get answersArgs for pawnContinueUsing
    const answersArgs: ItemAnswerArgs[] = carStillUsingQuestion
      ? [
          {
            questionId: itemQuestions.length && carStillUsingQuestion._id,
            selectedOptionIndex: pawnContinueUsing ? 0 : 1, // selected option index
          },
        ]
      : []

    // get answersArgs for all questions
    const allAnswersArgs: ItemAnswerArgs[] = itemQuestions.map((question) => {
      return {
        questionId: question._id,
        selectedOptionIndex: question.singleChoiceOptions?.findIndex(
          (choice) => choice.isDefaultValue,
        ),
      }
    })

    // update allAnswersUpdatedArgs if user choose the option
    const allAnswersUpdatedArgs = allAnswersArgs.map(
      (obj) => answersArgs.find((o) => o.questionId === obj.questionId) || obj,
    )

    return allAnswersUpdatedArgs
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemQuestions, pawnContinueUsing])

  const handleDealBooked = useCallback(
    (args: OnAfterDealBookedArgs) => {
      try {
        trackTransaction(args)
      } finally {
        modal.open(<SuccessMessage />, {
          variant: 'full',
          onAfterClose: () => {
            router.push('/')
          },
        })
      }
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal.open, router.push],
  )

  // ** OTHER FUNCTIONS ** //
  useEffect(() => {
    if (onlyCustomRequest) setIsCustomRequest(true)
  }, [onlyCustomRequest])

  // active custom request form
  const activeCustomRequestForm = () => {
    formikProps.setFieldValue('dataPrivacyTermsAccepted', false)
    formikProps.setFieldValue('fernAbsGAccepted', false)

    setIsCustomRequest(true)
    scrollToStart?.()
  }

  return (
    <Box width={['90%', '70%']} maxWidth="690px" marginX="auto" id={id}>
      {/* Car configure */}
      <VehiclePawnableQuestions
        baseVehicleCategoryId={baseVehicleCategoryId}
        onChangePawnable={setPawnable}
      />

      {/* if is custom request, show custom form else show normal form */}
      {pawnable && (
        <form>
          {isCustomRequest ? (
            <CustomVehicleRequest
              formikProps={formikProps}
              vehicleCategoryId={vehicleCategoryId}
              baseVehicleCategoryId={baseVehicleCategoryId}
              allAnswersUpdatedArgs={allAnswersUpdatedArgs}
              pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
              documentLinks={documentLinks}
              onBeforeDealBooked={trackCheckout}
              onAfterDealBooked={handleDealBooked}
            />
          ) : (
            <CarRequest
              setIsCustomRequest={setIsCustomRequest}
              formikProps={formikProps}
              vehicleCategoryId={vehicleCategoryId}
              baseVehicleCategoryId={baseVehicleCategoryId}
              allAnswersUpdatedArgs={allAnswersUpdatedArgs}
              pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
              carStillUsingQuestion={carStillUsingQuestion}
              activeCustomRequestForm={activeCustomRequestForm}
              documentLinks={documentLinks}
              onBeforeDealBooked={trackCheckout}
              onAfterDealBooked={handleDealBooked}
            />
          )}
        </form>
      )}
    </Box>
  )
}

export default CarPage

export const getVehicleCategorieId = (
  vehicleCategoryId: EVehicleCategory,
  continueUsing: boolean,
) => {
  if (continueUsing) {
    return vehicleCategoryId
  }

  switch (vehicleCategoryId) {
    case EVehicleCategory.CAR:
      return EVehicleCategory.CAR_STORED

    case EVehicleCategory.MOTORCYCLE:
      return EVehicleCategory.MOTORCYCLE_STORED

    default:
      throw new Error(`No stored variant for category id ${vehicleCategoryId}.`)
  }
}

const isContinueUsingQuestion = (
  question: Pick<ItemQuestion, 'titleKey'>,
): boolean => question.titleKey === 'QUESTION_CUSTOMER_CONTINUE_USING_ITEM'

const trackCheckout = ({
  customVehicleRequestData: { payoutAmount, make },
  definedVehicleProperties,
  trackingType,
}: OnBeforeDealBookedArgs) => {
  if (trackingType === 'Car') {
    tracking.beginCheckout([
      {
        title: [
          definedVehicleProperties.make,
          definedVehicleProperties.model,
          definedVehicleProperties.fuel,
          definedVehicleProperties.body,
          definedVehicleProperties.engine,
          definedVehicleProperties.horsePower,
          definedVehicleProperties.regdate,
        ]
          .filter(Boolean)
          .join(' '),
        actualValue: payoutAmount,
        contentCategories: {
          lvl0: 'Autos',
          lvl1: make,
        },
      },
    ])
  }
}

const trackTransaction = ({
  bookingNumber,
  customVehicleRequestData,
  definedVehicleProperties,
  trackingType,
  dealCalculation,
}: OnAfterDealBookedArgs) => {
  const payoutAmount =
    customVehicleRequestData?.payoutAmount ??
    dealCalculation?.dealValuesEntry?.payoutAmount

  if (trackingType === 'Car') {
    tracking.purchase(
      [
        {
          title: [
            definedVehicleProperties.make,
            definedVehicleProperties.model,
            definedVehicleProperties.fuel,
            definedVehicleProperties.body,
            definedVehicleProperties.engine,
            definedVehicleProperties.horsePower,
            definedVehicleProperties.regdate,
          ]
            .filter(Boolean)
            .join(' '),
          contentCategories: {
            lvl0: 'Autos',
            lvl1: definedVehicleProperties.make,
          },
          actualValue: payoutAmount,
        },
      ],
      {
        type: 'car',
        transaction_id:
          typeof bookingNumber === 'number'
            ? bookingNumber.toFixed(0)
            : bookingNumber,
      },
    )
  } else {
    tracking.trackEvent('custom_deal_request', {
      value: payoutAmount,
      type: 'car',
    })
  }
}

const createValidationSchema = (isCustomRequest: boolean, t: Translate) =>
  Yup.object({
    ...(isCustomRequest
      ? {
          model: Yup.string().required(),
          make: Yup.string().required(),
          horsePower: Yup.string().required(),
        }
      : {
          model: Yup.string().nullable(),
          make: Yup.string().nullable(),
          horsePower: Yup.string().nullable(),
        }),
    regdate: Yup.string().required(),
    odometer: Yup.number()
      .max(odometerMaxNumber, t('common:errors.car_pawn_odometer_too_big'))
      .required(),
    fuel: Yup.string().required(),
    payoutAmount: Yup.number().required(),
    pawnTime: Yup.number().required(),
    note: Yup.string(),
    dataPrivacyTermsAccepted: Yup.boolean().required(),
    fernAbsGAccepted: Yup.boolean().required(),
    pawnContinueUsing: Yup.boolean().required(),
  })

export interface OnBeforeDealBookedArgs {
  customVehicleRequestData: CustomVehicleRequestData
  definedVehicleProperties: Partial<VehiclePropertiesArgs>
  vehicleProperties?: CarProperty[]
  dealCalculation?: CarDealCalculation
  trackingType: TrackingTypes
}

export interface OnAfterDealBookedArgs {
  bookingNumber: number | string
  customVehicleRequestData: CustomVehicleRequestData
  definedVehicleProperties: Partial<VehiclePropertiesArgs>
  dealCalculation?: CarDealCalculation
  trackingType: TrackingTypes
}
