import AccountButton from '../Header/AccountButton'
import CartButton from '../Header/CartButton'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { StoryblokComponent } from '@storyblok/react'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import MenuButton from '@/app/common/components/Header/MenuButton'
import SearchBar from '@/app/common/components/Header/SearchBar'
import {
  CategoriesContainer,
  Center,
  DesktopPrimaryHeaderWrapper,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  GridItem,
  MainNavigation,
} from '@/app/common/components/Header/styled'
import Link from '@/app/common/components/Link/Link'
import config from '@/config'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import {
  CtaButtonStoryblok,
  HeaderLinkStoryblok,
} from '@/types/storyblok-component-types'
import SearchBarV2 from './SearchBarV2'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
  navigationBar?: (HeaderLinkStoryblok | CtaButtonStoryblok)[]
}

export const DesktopPrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  navigationBar,
}: Props) => {
  const router = useRouter()
  const isProfile = router.route.includes('/profile')
  const isCheckout = router.pathname.includes('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  const [isSearchActive, setIsSearchActive] = useState<boolean>(false)

  const onSearchStatusChange = (value: boolean) => {
    setIsSearchActive(value)
  }

  return (
    <DesktopPrimaryHeaderWrapper>
      <GridItem area="menu">
        <Center justifyContent="center">
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </Center>
      </GridItem>
      <GridItem area="logo">
        <Center>
          <HeaderLogo />
        </Center>
      </GridItem>

      {!isProfile && (
        <GridItem area="search">
          <Center>
            {config.isNewProductSearchEnabled ? (
              <SearchBarV2
                isMobile={false}
                isSearchActive={isSearchActive}
                setIsSearchActive={onSearchStatusChange}
              />
            ) : (
              <SearchBar
                isMobile={false}
                isSearchActive={isSearchActive}
                onSearchStatusChange={onSearchStatusChange}
              />
            )}
          </Center>
        </GridItem>
      )}

      {!isSearchActive && (
        <CategoriesContainer>
          {navigationBar?.map((item) =>
            item.component === 'cta-button' ? (
              <StoryblokComponent blok={item} key={item._uid} />
            ) : item.header_child_links?.length ? (
              <Dropdown key={item._uid}>
                <Link href={getStoryBlokLink(item.link)}>
                  <MainNavigation>{item.caption}</MainNavigation>
                </Link>
                <DropdownMenu
                  className="dropdown-list"
                  position={itemsCount == 0 ? 'right' : 'left'}
                >
                  {item.header_child_links.map((childLink) => (
                    <Link
                      href={getStoryBlokLink(childLink.link)}
                      key={childLink.caption}
                    >
                      <DropdownItem>{childLink.caption}</DropdownItem>
                    </Link>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ) : (
              <Link
                key={item._uid}
                href={getStoryBlokLink(item.link) ?? '#'}
                centerLinkContent
                useNextLink={item.link?.linktype !== 'url'}
              >
                <MainNavigation>{item.caption}</MainNavigation>
              </Link>
            ),
          )}
        </CategoriesContainer>
      )}

      {!isCheckout && !isFinalizeAccountSetup && itemsCount > 0 && (
        <GridItem zIndex={isSearchActive ? 0 : 1} area="cart">
          <Center justifyContent="center">
            <CartButton itemsCount={itemsCount} />
          </Center>
        </GridItem>
      )}

      {!isCheckout && !isFinalizeAccountSetup && (
        <AccountButton zIndex={isSearchActive ? 0 : 1} />
      )}

      {!isCheckout && (
        <GridItem zIndex={1} area="language">
          <LanguageSelector />
        </GridItem>
      )}
    </DesktopPrimaryHeaderWrapper>
  )
}
