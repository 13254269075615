import NotFoundResult from '../../NotFoundResult'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { AutocompleteProvided } from 'react-instantsearch-core'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { AnimatePresence } from '@/utils/animatePresence'

const ResultListWrapper = styled(motion.div)<{ $headerHeight: number }>`
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  height: 120%;
  min-width: 300px;
  margin-top: -8.2rem;
  background-color: transparent;

  ${media.md} {
    margin-top: -4.2rem;
    top: ${({ theme }) =>
      theme.sizes.headerHeight + theme.sizes.subHeaderHeight}px;
  }
`

const SearchResultDiv = styled.div`
  overflow: hidden;
  padding: 0;
  background-color: white;
  position: relative;
  --webkit-overflow-scrolling: touch;
  z-index: 1;
  min-width: 300px;
  margin-right: 9px;
  margin-left: 9px;
  margin-top: 11.4rem;

  border-radius: ${({ theme }) => theme.radius.normal}px;

  ${media.md} {
    padding: 0;
    left: -1px;
    right: 0;
    margin-top: 4.1rem;
    width: 500px;
    margin-left: 14.5rem;
  }
`

const RemoveScrollStyled = styled(RemoveScroll)`
  overflow: auto;
  scrollbar-width: thin;
`

const ResultListList = styled.ul`
  height: 600px;

  li {
    padding: 1rem 1rem;
    cursor: pointer;
    transition: background-color 0.1s ease;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    font-size: 14px;

    ${media.md} {
      padding: 1rem;
    }

    &:hover {
      transition: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    strong {
      font-weight: 600;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`

const ListElement = styled.li<{
  isSelected?: boolean
}>`
  ${(props) =>
    props.isSelected &&
    `
    transition: none;
    background-color: rgba(0, 0, 0, 0.1);
  `}
`

const scrollIfNeed = (selectedRef: any) => {
  if (selectedRef.current) {
    const element = selectedRef.current as any
    const parent = element && element.parentElement
    if (!element || !parent) {
      return
    }
    const overTop = element.offsetTop - parent.offsetTop < parent.scrollTop
    const overBottom =
      element.offsetTop - parent.offsetTop + element.clientHeight >
      parent.scrollTop + parent.clientHeight
    if (overTop || overBottom) {
      parent.scrollTop =
        element.offsetTop -
        parent.offsetTop -
        parent.clientHeight / 2 +
        element.clientHeight / 2
    }
  }
}

interface ResultListProps extends AutocompleteProvided {
  isSearchActive: boolean
  setIsSearchActive: (value: boolean) => void
  selectedIndex?: number
  onResultClick?: (path: string) => void
}

const ResultList: FunctionComponent<ResultListProps> = ({
  currentRefinement,
  refine,
  hits,
  selectedIndex,
  onResultClick,
  isSearchActive,
  setIsSearchActive,
}) => {
  const selectedRef = useRef(null)
  const { asPath } = useRouter()
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    scrollIfNeed(selectedRef)
  }, [selectedIndex])

  useEffect(() => {
    setHeaderHeight(document.querySelector('header')?.clientHeight || 0)
  }, [asPath])

  return (
    <AnimatePresence>
      {isSearchActive && (
        <ResultListWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
          onClick={() => {
            setIsSearchActive(false)
            refine('')
          }}
          id="result-list-wrapper"
          $headerHeight={headerHeight}
        >
          {currentRefinement && (
            <SearchResultDiv>
              <RemoveScrollStyled>
                <ResultListList>
                  {hits.length === 0 ? (
                    <NotFoundResult searchedItem={currentRefinement} />
                  ) : (
                    hits.map((hit, i) => (
                      <Link
                        href={{
                          pathname: '/item/[slug]',
                          query: { slug: hit.slug },
                        }}
                        key={hit.slug}
                        legacyBehavior
                      >
                        <ListElement
                          id={`search-result-item_${hit.slug}`}
                          isSelected={i === selectedIndex}
                          ref={i === selectedIndex ? selectedRef : null}
                          dangerouslySetInnerHTML={{
                            __html: hit.title.replace(
                              new RegExp(
                                currentRefinement.replace(
                                  /[.*+?^${}()|[\]\\]/g,
                                  '\\$&',
                                ),
                                'gi',
                              ),
                              (match) => `<strong>${match}</strong>`,
                            ),
                          }}
                          onClick={() => {
                            if (typeof onResultClick === 'function') {
                              onResultClick(asPath)
                            }
                          }}
                        />
                      </Link>
                    ))
                  )}
                </ResultListList>
              </RemoveScrollStyled>
            </SearchResultDiv>
          )}
        </ResultListWrapper>
      )}
    </AnimatePresence>
  )
}

export default ResultList
