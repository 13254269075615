import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { useQuery } from '@/app/common/graphql/hooks'
import { GraphQLPrefetchClient } from '@/app/common/graphql/prefetchClient'
import { ObjectListItem } from '@/domains/items/components/ObjectList'
import {
  ProductSelectorMainProductsQueryDocument,
  ProductSelectorMainProductsQueryQueryVariables,
} from '@/types/gql/graphql'
import ProductSelectorProducts, {
  ProductSelectorProductsProps,
} from './ProductSelectorProducts'

interface ProductSelectorMainProductsProps
  extends Omit<ProductSelectorProductsProps, 'products' | 'totalPages'> {
  productIds: string[]
  categoryIds: string[]
  renderMissingProductLink: (() => JSX.Element) | undefined
}

export default function ProductSelectorMainProducts({
  renderMissingProductLink,
  ...props
}: ProductSelectorMainProductsProps) {
  const { t } = useTranslation()

  // don't paginate here because we don't know which of the products selected in storyblok are still published
  // (-> can't know the number of pages) and the payload per product is relatively small
  // TODO: this can be fixed by returning the number of products on top of only the products themselves
  const { data } = useQuery(ProductSelectorMainProductsQueryDocument, {
    variables: {
      productIds: props.productIds,
      categoryIds: props.categoryIds,
    },
    enableCaching: true,
  })

  const products = useMemo(
    () =>
      data?.products
        ? props.productIds
            .map((productId) => data.products.find((p) => p._id === productId))
            .filter(
              (product): product is Exclude<typeof product, undefined> =>
                !!product,
            )
        : [],
    [data?.products, props.productIds],
  )

  const hasOtherProducts = (data?.otherProducts.length ?? 0) > 0
  const cardsCount =
    hasOtherProducts || renderMissingProductLink
      ? products.length + 1
      : products.length

  return (
    <ProductSelectorProducts
      {...props}
      products={products}
      totalPages={Math.ceil(cardsCount / props.pageSize)}
      renderExtraItem={
        hasOtherProducts
          ? () => (
              <ObjectListItem
                title={t('common:other_products')}
                href={{
                  query: {},
                  search: 'other_products=1',
                }}
                Image={
                  <StoryblokImage
                    image={props.fallbackImage}
                    layout="responsive"
                    objectFit="cover"
                    sizes={props.imageSizes}
                    priority
                  />
                }
              />
            )
          : renderMissingProductLink
      }
    />
  )
}

export function prefetchNewProductSelectorMainProducts(
  client: GraphQLPrefetchClient,
  variables: ProductSelectorMainProductsQueryQueryVariables,
) {
  return client.executeQuery({
    document: ProductSelectorMainProductsQueryDocument,
    variables,
  })
}
