import { useStoryblokConfig } from '../../context/storyblokConfigContext'
import { DesktopPrimaryHeader } from '../Header/DesktopPrimaryHeader'
import { motion, useViewportScroll } from 'framer-motion'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { HeaderLayout } from '@/app/common/components/Header/HeaderLayout'
import { MobilePrimaryHeader } from '@/app/common/components/Header/MobilePrimaryHeader'
import { MobileSecondaryHeader } from '@/app/common/components/Header/MobileSecondaryHeader'
import {
  PrimaryHeaderCheckout,
  TabletPrimaryHeader,
} from '@/app/common/components/Header/TabletPrimaryHeader'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import {
  dealCalculationState,
  selectedItemsState,
} from '@/domains/checkout/checkout.state'
import useWindowDimensions_CAN_CAUSE_CLS from '@/helpers/useWindowDimensions'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import Menu from './Menu'

const Header: FunctionComponent = () => {
  const { config } = useStoryblokConfig()
  const { navigation_bar } = config
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => setMenuOpen(!menuOpen)

  const modalDialog = useContext(ModalDialogContext)

  const router = useRouter()
  const [items] = useRecoilStateWithSSRFallback(selectedItemsState, [])
  const [dealCalculation] = useRecoilState(dealCalculationState)
  const { scrollY } = useViewportScroll()
  const [isCompact, setIsCompact] = useState(false)
  const { activeBreakpoints } = useWindowDimensions_CAN_CAUSE_CLS()

  const isProfile = router.route.includes('/profile')
  const isCheckout = router.pathname.startsWith('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  useEffect(() => {
    function updateSearchVisible() {
      if (activeBreakpoints.includes('lg')) {
        setIsCompact(true)
        return true
      }
      const pos = scrollY.get()

      if (pos > 10) {
        setIsCompact(true)
      } else {
        setIsCompact(false)
      }
    }
    const unsubscribeScroll = scrollY.onChange(updateSearchVisible)
    return () => {
      unsubscribeScroll()
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBreakpoints])

  const itemsCount = items.length
  const itemsTotalAmount =
    dealCalculation?.dealValuesEntry.payoutAmount.toFixed(2) as string

  const navMenu = (
    <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} isCompact={isCompact} />
  )
  if (isCheckout || isFinalizeAccountSetup) {
    return (
      <HeaderLayout
        isCheckout
        isCompact
        primaryHeader={
          <>
            <PrimaryHeaderCheckout
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              itemsCount={0} // dont show cart as we are already in the checkout
              itemsTotalAmount=""
            />
            <MobilePrimaryHeader
              isCheckout
              menuOpen={menuOpen}
              toggleMenu={toggleMenu}
              itemsCount={itemsCount}
              itemsTotalAmount={itemsTotalAmount}
            />
          </>
        }
        navMenu={navMenu}
      />
    )
  }

  return (
    <HeaderLayout
      isCompact={isProfile}
      primaryHeader={
        <>
          <TabletPrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            itemsCount={itemsCount}
            itemsTotalAmount={itemsTotalAmount}
          />

          <DesktopPrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            itemsCount={itemsCount}
            itemsTotalAmount={itemsTotalAmount}
            navigationBar={navigation_bar}
          />

          <MobilePrimaryHeader
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            itemsCount={itemsCount}
            itemsTotalAmount={itemsTotalAmount}
          />
        </>
      }
      secondaryHeader={
        !isProfile && (
          <ScrollAnimationContainer
            variants={variants}
            hide={modalDialog.isOpen}
          >
            <MobileSecondaryHeader isCompact={isCompact} />
          </ScrollAnimationContainer>
        )
      }
      navMenu={navMenu}
    />
  )
}

export default Header

const ScrollAnimationContainer = styled(motion.div)<{ hide: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  position: relative;
  box-sizing: border-box;
  background-color: white;

  ${({ hide }) => hide && 'display: none;'}

  ${media.sm} {
    display: block;
  }
`

const variants = {
  visible: {
    top: 0,
    height: theme.sizes.headerHeight,
    opacity: 1,
  },
  hidden: {
    top: -theme.sizes.headerHeight,
    height: 0,
    opacity: 0,
  },
}
