import { CustomVehicleRequestData } from '..//CustomVehicleRequest'
import { AutoDocumentLinks } from '../TermsAndSubmit/TermsAndSubmit'
import { VehicleCalculationBox } from '../VehicleCalculationBox/VehicleCalculationBox'
import {
  CarCalculation,
  CarProperty,
  CustomVehicleFormData,
  OnAfterDealBookedArgs,
  OnBeforeDealBookedArgs,
  definedCarPropertyKeys,
} from '../VehicleDealBooking'
import * as Sentry from '@sentry/nextjs'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'
import { UseFormReturnType } from '@/app/common/components/Form/useForm'
import { useMutation, useQuery } from '@/app/common/graphql/hooks'
import useEnsureCustomerLoggedIn from '@/domains/car/hooks/useEnsureCustomerLoggedIn'
import { getTotalGrossFeeAmountOfType } from '@/helpers/feesCalculation'
import { EVehicleCategory } from '@/types'
import {
  Company,
  CreateCustomDealDocument,
  CreateCustomDealMutation,
  CreateCustomDealMutationVariables,
  CustomDealCalculationDocument,
  CustomDealCalculationQuery,
  CustomDealCalculationQueryVariables,
  EDealType,
  ItemAnswerArgs,
  VehicleDataArgs,
  VehiclePropertiesArgs,
} from '@/types/gql/graphql'
import { printLocalAmount } from '@/utils/misc'

// Interfaces
interface CarCalculationWithNoValuationProps {
  pawnTimeOptionsInMonths: number[]
  formikProps: UseFormReturnType<CustomVehicleFormData>
  company?: Pick<Company, '_id'>
  allAnswersUpdatedArgs: ItemAnswerArgs[]
  baseVehicleCategoryId: EVehicleCategory
  vehicleProperties: CarProperty[]
  vehicleCategoryId: string
  documentLinks: AutoDocumentLinks
  onBeforeDealBooked?: (args: OnBeforeDealBookedArgs) => void | Promise<void>
  onAfterDealBooked?: (args: OnAfterDealBookedArgs) => void | Promise<void>
}

export const CarCalculationWithNoValuation: FunctionComponent<
  CarCalculationWithNoValuationProps
> = ({
  pawnTimeOptionsInMonths,
  formikProps,
  company,
  allAnswersUpdatedArgs,
  baseVehicleCategoryId,
  vehicleProperties,
  vehicleCategoryId,
  documentLinks,
  onBeforeDealBooked,
  onAfterDealBooked,
}) => {
  const { t } = useTranslation()
  const { odometer, pawnTime, payoutAmount, note } = formikProps.values

  const [createCustomDeal, createCustomDealResult] = useMutation<
    CreateCustomDealMutation,
    CreateCustomDealMutationVariables
  >(CreateCustomDealDocument)
  const customDealCalculationRes = useQuery<
    CustomDealCalculationQuery,
    CustomDealCalculationQueryVariables
  >(CustomDealCalculationDocument, {
    skip: !payoutAmount || !formikProps.isValid || !company,
    variables: {
      customDealCalculationArgs: {
        isReversedFeeCalculation: true,
        companyId: company?._id,
        durationInDays: pawnTime * 30,
        calculationItems: [
          {
            payoutAmount,
            itemCategoryId: vehicleCategoryId,
            answers: allAnswersUpdatedArgs,
          },
        ],
      },
    },
  })

  const customRequestCalculation =
    customDealCalculationRes.data?.customDealCalculation

  const calculation = (
    customRequestCalculation && !customDealCalculationRes.isFetching
      ? {
          calculation: customRequestCalculation,
          payoutAmount: customRequestCalculation.payoutAmount,
          paybackAmount:
            customRequestCalculation.payoutAmount +
            getTotalGrossFeeAmountOfType(
              customRequestCalculation.appliedUsedFeeDefinitions,
            ),
          feesCaclulation: getTotalGrossFeeAmountOfType(
            customRequestCalculation.appliedUsedFeeDefinitions,
          ),
          appliedUsedFeeDefinitions:
            customRequestCalculation.appliedUsedFeeDefinitions,
          dealItems: [
            {
              title: vehicleProperties
                .map((property) => property.selected?.name)
                .join(' '),
            },
          ],
        }
      : undefined
  ) as CarCalculation | undefined

  const ensureCustomerLoggedIn = useEnsureCustomerLoggedIn()
  const _onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (!formikProps.isValid) return

    const customVehicleRequestData =
      formikProps.values as CustomVehicleRequestData
    const definedVehicleProperties: Partial<VehiclePropertiesArgs> & any = {
      odometer,
    }

    if (!company) {
      console.warn('Company not loaded.')
      return
    }

    if (!customRequestCalculation || customDealCalculationRes.isFetching) return

    vehicleProperties.forEach((c) => {
      if (definedCarPropertyKeys.includes(c.name)) {
        definedVehicleProperties[c.name] =
          c.name === 'odometer' ? Number(c.selected?.name) : c.selected?.name
      }
    })

    const otherVehicleProperties = vehicleProperties.filter(
      (c) => !definedCarPropertyKeys.includes(c.name),
    )

    const vehicleData: VehicleDataArgs = {
      vehicleProperties: {
        ...definedVehicleProperties,
        otherVehicleProperties:
          otherVehicleProperties.length > 0
            ? otherVehicleProperties
                .filter((p) => p.selected)
                .map((p) => ({
                  name: p.name,
                  value: p.selected?.name,
                }))
            : undefined,
      },
    }

    const trackingType =
      baseVehicleCategoryId === EVehicleCategory.CAR ? 'CarCR' : 'MotorcycleCR'

    if (onBeforeDealBooked) {
      try {
        await onBeforeDealBooked({
          customVehicleRequestData,
          definedVehicleProperties,
          trackingType,
          vehicleProperties,
        })
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    const customerId = await ensureCustomerLoggedIn({
      headline: t('vehicle:signup_modal.headline', {
        amount: printLocalAmount({ number: payoutAmount, fractionDigits: 0 }),
      }),
      barTitle: t('vehicle:signup_modal.barTitle'),
      description: t('vehicle:signup_modal.description', {
        vehicleName:
          baseVehicleCategoryId === EVehicleCategory.CAR
            ? 'Autos'
            : 'Motorrades',
      }),
    })

    if (customerId === null) {
      return
    }

    const res = await createCustomDeal({
      variables: {
        customDealCreateArgs: {
          companyId: company._id,
          contactData: {
            email: '',
            phone: '',
          },
          customerId,
          durationInDays: pawnTime * 30,
          items: [
            {
              itemCategoryId: vehicleCategoryId,
              title:
                vehicleData?.vehicleProperties.make ??
                '' + ' ' + vehicleData?.vehicleProperties.model ??
                '',
              vehicleData,
              pawnPayoutAmount: payoutAmount,
              purchasePayoutAmount: payoutAmount,
              answers: allAnswersUpdatedArgs,
              note: note ?? '',
            },
          ],
          date: customRequestCalculation.date,
          dealType: EDealType.Pawn,
        },
      },
    })

    if (onAfterDealBooked) {
      if (!res.data) {
        return
      }

      const dealId = res.data.createCustomDeal._id
      await onAfterDealBooked({
        bookingNumber: dealId,
        customVehicleRequestData,
        definedVehicleProperties,
        trackingType,
      })
    }
  }

  return (
    <VehicleCalculationBox
      calculationLoading={customDealCalculationRes.isFetching}
      marketValueToSmall={false}
      calculation={calculation}
      pawnTimeOptionsInMonths={pawnTimeOptionsInMonths}
      baseVehicleCategoryId={baseVehicleCategoryId}
      createDealResult={createCustomDealResult}
      formikProps={formikProps}
      onSubmit={_onSubmit}
      isCustomRequest={false}
      documentLinks={documentLinks}
      noValuation
    />
  )
}
