import { useRouter } from 'next/router'
import React, { FunctionComponent, createContext, useContext } from 'react'
import { EnlargeWhenActive, HighlightWhenActive } from './Animation'
import Autocomplete from './Autocomplete/Autocomplete'

interface SearchBarProps {
  isMobile: boolean
  isSearchActive: boolean
  setIsSearchActive: (active: boolean) => void
}

const SearchBar: FunctionComponent<SearchBarProps> = ({
  isMobile,
  isSearchActive,
  setIsSearchActive,
}) => {
  const router = useRouter()

  if (router.route.includes('/checkout')) {
    return null
  }

  return (
    <SearchBarContext.Provider
      value={{
        isSearchActive,
        setIsSearchActive,
      }}
    >
      <EnlargeWhenActive isMobile={isMobile}>
        <HighlightWhenActive>
          <Autocomplete />
        </HighlightWhenActive>
      </EnlargeWhenActive>
    </SearchBarContext.Provider>
  )
}

interface SearchBarContextType {
  setIsSearchActive: (value: boolean) => void
  isSearchActive: boolean
}

const SearchBarContext = createContext<SearchBarContextType>({
  isSearchActive: false,
  setIsSearchActive: () => {},
})

export const useSearchBarContext = () => {
  const context = useContext(SearchBarContext)
  if (!context)
    throw new Error('Need to be wrapped by SearchBarContextProvider')

  return context
}

export default SearchBar
