import AccountIcon from '../../../assets/ico_desktop_account.svg'
import LoggedInAccountIcon from '../../../assets/ico_desktop_account_loggedIn.svg'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import {
  Center,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  GridItem,
} from '@/app/common/components/Header/styled'
import Link from '@/app/common/components/Link/Link'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { UnstyledButton } from '@/style/components/Button'

interface AccountButtonProps {
  zIndex?: number
}

const headerPrefix = 'header'

const AccountButton: FunctionComponent<AccountButtonProps> = ({ zIndex }) => {
  const { t } = useTranslation()

  const [customer] = useRecoilState(customerState)
  const modal = useContext(ModalDialogContext)

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    setIsLoggedIn(!!customer)
  }, [customer])

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  const logoutCustomer = useLogoutCustomer()

  return (
    <>
      {isLoggedIn ? (
        <GridItem zIndex={zIndex} area="account">
          <Dropdown>
            <Link id={`${headerPrefix}_profile`} href={'/profile'}>
              <AccountButtonWrapper loggedIn={isLoggedIn}>
                <LoggedInAccountIcon />
              </AccountButtonWrapper>
            </Link>

            <DropdownMenu className="dropdown-list" position={'right'}>
              <Link id={`${headerPrefix}_profile`} href={'/profile'}>
                <DropdownItem>{t('common:header.profile')}</DropdownItem>
              </Link>

              <DropdownItem onClick={logoutCustomer}>
                {t('common:auth.logout')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </GridItem>
      ) : (
        <GridItem
          zIndex={zIndex}
          area="account"
          onClick={handleLoginClick}
          id={`${headerPrefix}_login-or-signup`}
        >
          <Center justifyContent="center">
            <AccountButtonWrapper loggedIn={isLoggedIn}>
              <AccountIcon />
            </AccountButtonWrapper>
          </Center>
        </GridItem>
      )}
    </>
  )
}

export default AccountButton

const AccountButtonWrapper = styled(UnstyledButton)<{ loggedIn: boolean }>`
  ${({ loggedIn }) =>
    loggedIn &&
    `
      margin-top: 0.58rem;
    `};
  justify-content: center;
  align-items: center;
  display: flex;
`
