import useTranslation from 'next-translate/useTranslation'
import StickyBox from 'react-sticky-box'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import LoadingOverlay from '@/app/common/components/LoadingOverlay/LoadingOverlay'
import { Button } from '@/style/components/Button'
import LegacyText from '@/style/components/LegacyText'
import { SectionInner } from '@/style/components/Section'
import Text from '@/style/components/Text'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import { SelectedProduct } from '@/types'
import { ProductVariant } from '@/types/gql/graphql'
import { printLocalFloat } from '@/utils/misc'

interface Props {
  isFetching: boolean
  isUnderMinimumItemValue: boolean
  isDysfunctional: boolean
  variant?: Pick<ProductVariant, 'id' | 'isPriceValid'> | undefined
  onSubmit: () => void
  isDisabled: boolean
  product: SelectedProduct
  itemValue?: number
  formattedItemValue?: string
}

export function DesktopConfiguratorSummary({
  isFetching,
  itemValue,
  variant,
  isUnderMinimumItemValue,
  isDysfunctional,
  onSubmit,
  isDisabled,
  product,
}: Props) {
  const { t } = useTranslation()

  const displayItemValue = (itemValue?: number) => {
    if (!itemValue) return ''

    return isUnderMinimumItemValue ? '- ' : printLocalFloat(itemValue, 0)
  }

  return (
    <DesktopStickyBox offsetTop={142}>
      <SummaryOuterWrapper sizing="narrow">
        <SummaryWrapper>
          {isFetching && <LoadingOverlay fullScreen={false} />}
          <TextWrapper>
            <Text.h6 noMargin>
              {!variant?.isPriceValid && !isDysfunctional
                ? t('product:summary.offer_on_request.headline')
                : isDysfunctional
                  ? t('product:only_functioning_items_accepted').toUpperCase()
                  : itemValue === 0
                    ? t('product:no_payout_possible')
                    : `${t('product:summary.headline')}:`}
            </Text.h6>
          </TextWrapper>

          {!isDysfunctional && !variant?.isPriceValid ? (
            <OfferOnRequestWrapper>
              {t('product:summary.offer_on_request.description')}
            </OfferOnRequestWrapper>
          ) : (
            <PriceContainer>
              <Row>
                <Col xs={12}>
                  <SummaryTotal weight="bold">
                    {displayItemValue(itemValue)}
                  </SummaryTotal>
                  <SummarySymbol>€</SummarySymbol>
                </Col>
              </Row>
              {isUnderMinimumItemValue ? (
                <ErrorDescription>
                  {t('product:under_minimum_item_value')}
                </ErrorDescription>
              ) : null}
            </PriceContainer>
          )}
          <PriceContainer>
            <SubmitButton
              id={`ITEM_PAGE_${product.slug}_NEXT_BUTTON`}
              primary={!isDisabled}
              full
              disabled={isDisabled}
              onClick={onSubmit}
            >
              {t(
                variant?.isPriceValid
                  ? 'product:summary.button_label'
                  : 'product:summary.offer_on_request.button_label',
              )}
            </SubmitButton>
            {!isDisabled && variant?.isPriceValid && (
              <LegacyText.p align="center">
                <LegacyText.sm color="light">
                  {t('product:summary.button_description')}
                </LegacyText.sm>
              </LegacyText.p>
            )}
          </PriceContainer>
        </SummaryWrapper>
      </SummaryOuterWrapper>
    </DesktopStickyBox>
  )
}

const DesktopStickyBox = styled(StickyBox)`
  @media (max-width: ${theme.breakpoints.md}px) {
    display: none;
  }
`

const TextWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0px;
  text-align: center;
`

const SubmitButton = styled(Button)`
  :disabled {
    opacity: 0.2;
  }
  font-size: 0.875rem;
  ${media.md} {
    font-size: 1rem;
  }
`

const SummaryOuterWrapper = styled(SectionInner)`
  position: relative;

  > * {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 2rem;
    right: 0;
    z-index: -1;
    background-color: ${({ theme }) => theme.colors.buttonBorder};
    border-bottom-left-radius: ${({ theme }) => theme.radius.semiLarge}px;
    border-bottom-right-radius: ${({ theme }) => theme.radius.semiLarge}px;

    ${media.xs} {
      border-bottom-left-radius: ${({ theme }) => theme.radius.large}px;
      border-bottom-right-radius: ${({ theme }) => theme.radius.large}px;
    }
  }

  ${media.md} {
    padding: 0 !important;

    &::after {
      display: none;
    }
  }
`

const SummaryWrapper = styled.aside`
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  border-radius: ${({ theme }) => theme.radius.normal}px;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.md} {
    max-width: 25rem;
    margin: 3rem 2rem 2rem;
  }
`

const SummarySymbol = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  display: inline-block;

  ${media.md} {
    font-size: 2.375rem;
  }
`

const PriceContainer = styled.div`
  padding: 0 2rem;

  ${Row} {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0.5rem;
      right: 0.5rem;
    }
  }

  ${Col} {
    position: relative;
    text-align: center;

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        display: block;
        right: 0.5rem;
        bottom: 0.5rem;
        height: 2rem;
        border-right: 1px solid ${({ theme }) => theme.colors.border};
      }
    }
  }

  p {
    margin: 0.5em 0;
    line-height: 1.2;
  }

  button {
    margin-top: 1.5rem;
  }
`

const ErrorDescription = styled.section`
  font-size: 0.8rem;
  text-align: left;
  padding: 0 0.3rem;
  font-weight: 500;
  color: red;
  ${media.md} {
    padding: 0 1rem;
  }
`

const SummaryTotal = styled(LegacyText.xxxl)`
  font-size: 3.25;
  ${media.md} {
    font-size: 3.125rem;
  }
`

const OfferOnRequestWrapper = styled(TextWrapper)`
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
`
