import { motion } from 'framer-motion'
import { FunctionComponent, PropsWithChildren } from 'react'
import { styled } from 'styled-components'
import { AnimatePresence } from '@/utils/animatePresence'
import { useSearchBarContext } from './SearchBarV2'

interface EnlargeWhenActiveProps extends PropsWithChildren {
  isMobile: boolean
}

export const EnlargeWhenActive: FunctionComponent<EnlargeWhenActiveProps> = ({
  isMobile,
  children,
}) => {
  const { isSearchActive } = useSearchBarContext()

  return (
    <AnimatePresence>
      <motion.div
        animate={{
          width: isMobile ? '100%' : isSearchActive ? '500px' : '300px',
        }}
        transition={{ delay: 0.1 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export const HighlightWhenActive: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isSearchActive } = useSearchBarContext()

  return (
    <AnimatePresence>
      <StyledMotionDiv
        isSearchActive={isSearchActive}
        animate={{
          boxShadow: ` 0 0 0 100vmax  ${isSearchActive ? dimColor : 'rgba(0,0,0,0)'} `,
        }}
      >
        {children}
      </StyledMotionDiv>
    </AnimatePresence>
  )
}

const StyledMotionDiv = styled(motion.div)<{ isSearchActive: boolean }>`
  background-color: ${(props) =>
    props.isSearchActive ? dimColor : 'transparent'};
  border-radius: ${({ theme }) => theme.radius.normal}px;
`

const dimColor = 'rgba(0, 0, 0, 0.7)'
