import NotFoundResult from '../../NotFoundResult'
import { useSearchBarContext } from '../SearchBarV2'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import styled from 'styled-components'
import { media } from '@/style/helpers'
import { AnimatePresence } from '@/utils/animatePresence'

export type Result = {
  slug: string
  title: string
}

interface ResultListProps {
  selectedIndex?: number
  onResultClick?: (path: string) => void
  searchTerm: string
  onBackdropClick: () => void
  searchResults?: Result[]
}

const ResultList: FunctionComponent<ResultListProps> = ({
  searchTerm,
  onBackdropClick,
  searchResults,
  selectedIndex,
}) => {
  const resultsMapRef = useRef<Map<string, HTMLLIElement>>()
  const { asPath } = useRouter()
  const [headerHeight, setHeaderHeight] = useState(0)
  const { isSearchActive, setIsSearchActive } = useSearchBarContext()

  if (!resultsMapRef.current) {
    resultsMapRef.current = new Map()
  }

  useLayoutEffect(() => {
    setHeaderHeight(document.querySelector('header')?.clientHeight || 0)
  }, [asPath])

  const highlightMatchesRegex = useMemo(() => {
    // escape all incoming regexes (https://github.com/sindresorhus/escape-string-regexp)
    const escapedInput = searchTerm
      .trim()
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d')
    // match searched words individually as groups, instead of the whole search term
    const escapedInputWords = escapedInput.replace(/\s+/g, '|')
    return new RegExp(`(${escapedInputWords})`, 'gi')
  }, [searchTerm])

  const highlightMatches = (title: string) => {
    return (
      <>
        {title
          .split(highlightMatchesRegex)
          .map((part, i) =>
            highlightMatchesRegex.test(part) ? (
              <strong key={i}>{part}</strong>
            ) : (
              <span key={i}>{part}</span>
            ),
          )}
      </>
    )
  }

  useEffect(() => {
    if (
      selectedIndex !== undefined &&
      searchResults &&
      searchResults[selectedIndex]
    ) {
      const node = resultsMapRef.current!.get(searchResults[selectedIndex].slug)
      node?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [selectedIndex, searchResults])

  if (!isSearchActive) return

  return (
    <AnimatePresence>
      <ResultListWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.1 }}
        onClick={() => {
          setIsSearchActive(false)
          onBackdropClick()
        }}
        id="result-list-wrapper"
        $headerHeight={headerHeight}
      >
        {searchTerm && (
          <RemoveScroll>
            <SearchResultDiv>
              {searchResults?.length === 0 ? (
                <NotFoundResult searchedItem={searchTerm} />
              ) : (
                searchResults?.map((result, index) => (
                  <Link
                    href={{
                      pathname: '/products/[slug]',
                      query: { slug: result.slug },
                    }}
                    key={result.slug}
                    legacyBehavior
                  >
                    <ListElement
                      id={`search-result-item_${result.slug}`}
                      isSelected={index === selectedIndex}
                      ref={(node) => {
                        if (node) {
                          resultsMapRef.current!.set(result.slug, node)
                        } else {
                          resultsMapRef.current!.delete(result.slug)
                        }
                      }}
                      onClick={() => setIsSearchActive(false)}
                    >
                      {highlightMatches(result.title)}
                    </ListElement>
                  </Link>
                ))
              )}
            </SearchResultDiv>
          </RemoveScroll>
        )}
      </ResultListWrapper>
    </AnimatePresence>
  )
}

const ResultListWrapper = styled(motion.div)<{ $headerHeight: number }>`
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  height: 120%;
  min-width: 300px;
  margin-top: -8.2rem;
  background-color: transparent;

  ${media.md} {
    margin-top: -4.2rem;
    top: ${({ theme }) =>
      theme.sizes.headerHeight + theme.sizes.subHeaderHeight}px;
  }
`

const SearchResultDiv = styled.ul`
  overflow: auto;
  scrollbar-width: thin;

  padding: 0;
  background-color: white;
  position: relative;
  --webkit-overflow-scrolling: touch;
  z-index: 1;
  min-width: 300px;
  margin-right: 9px;
  margin-left: 9px;
  margin-top: 11.4rem;

  border-radius: ${({ theme }) => theme.radius.normal}px;

  ${media.md} {
    padding: 0;
    left: -1px;
    right: 0;
    margin-top: 4.1rem;
    width: 500px;
    margin-left: 14.5rem;
  }

  height: 600px;

  li {
    padding: 1rem 1rem;
    cursor: pointer;
    transition: background-color 0.1s ease;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    font-size: 14px;

    ${media.md} {
      padding: 1rem;
    }

    &:hover {
      transition: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    strong {
      font-weight: 600;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`

const ListElement = styled.li<{
  isSelected?: boolean
}>`
  ${(props) =>
    props.isSelected &&
    `
    transition: none;
    background-color: rgba(0, 0, 0, 0.1);
  `}
`

export default ResultList
