import { Pagination } from '@/app/common/components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import ObjectList, {
  ObjectListItem,
} from '@/domains/items/components/ObjectList'
import { ProductSelectorProducts_ProductsFragmentFragment } from '@/types/gql/graphql'
import { AssetStoryblok } from '@/types/storyblok-component-types'

export interface ProductSelectorProductsProps {
  products: ProductSelectorProducts_ProductsFragmentFragment[]
  fallbackImage: AssetStoryblok
  imageSizes: string
  pageSize: number
  totalPages: number
  pageIndex: number
  onChangePageIndex: (pageIndex: number) => void
  renderExtraItem?: () => JSX.Element
}

export default function ProductSelectorProducts({
  pageSize,
  totalPages,
  pageIndex,
  ...props
}: ProductSelectorProductsProps) {
  const isLastPage = pageIndex + 1 === totalPages
  return (
    <>
      <ObjectList numberOfItems={pageSize}>
        {props.products
          .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
          .map((product) => {
            return (
              <ObjectListItem
                key={product._id}
                title={product.name}
                href={{
                  pathname: '/products/[slug]',
                  query: { slug: product.slug },
                }}
                ribbon={false}
                Image={
                  <StoryblokImage
                    image={props.fallbackImage}
                    layout="responsive"
                    objectFit="cover"
                    sizes={props.imageSizes}
                    priority
                  />
                }
              />
            )
          })}

        {isLastPage && props.renderExtraItem?.()}
      </ObjectList>
      <Pagination
        variant="outlined"
        shape="rounded"
        siblingCount={0}
        count={totalPages}
        page={pageIndex + 1}
        onChange={(_, v) => props.onChangePageIndex(v)}
      />
    </>
  )
}
