import ResultList from '../ResultList'
import { motion } from 'framer-motion'
import useTranslation from 'next-translate/useTranslation'
import { NextRouter, useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import {
  AutocompleteProvided,
  StateResultsProvided,
} from 'react-instantsearch-core'
import styled from 'styled-components'
import tracking from '@/helpers/tracking'
import { UnstyledButton } from '@/style/components/Button'
import { AnimatePresence } from '@/utils/animatePresence'
import IconSearch from './icon_search.svg'

const dimColor = 'rgba(0, 0, 0, 0.7)'

const AutocompleteWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  min-width: 300px;
  width: 100%;
  z-index: 0;
`

const SearchBox = styled.input`
  -webkit-appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-right: none;
  flex: 200;
  border-radius: ${({ theme }) => theme.radius.normal}px 0 0
    ${({ theme }) => theme.radius.normal}px;
  padding: 0.65rem 1rem 0.65rem;
  font-size: ${({ theme }) => theme.fonts.sizes.sm.fontSize}rem;
  line-height: normal;
  outline: none;
  font-family: inherit;
  height: 2.8rem;
  &::placeholder {
    color: #5a5b5a;
  }
`

const SearchButton = styled(UnstyledButton)`
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 0 ${({ theme }) => theme.radius.normal}px
    ${({ theme }) => theme.radius.normal}px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.8rem;
`

const StyledMotionDiv = styled(motion.div)<{ isSearchActive: boolean }>`
  background-color: ${(props) =>
    props.isSearchActive ? dimColor : 'transparent'};
  border-radius: ${({ theme }) => theme.radius.normal}px;
`
const FakeForm = styled.div`
  display: none;
`

const SearchIcon = styled(IconSearch)``

const Autocomplete: FunctionComponent<
  AutocompleteProvided &
    StateResultsProvided & {
      onResultClick?: (path: string) => void
      isSearchActive: boolean
      handleSearchStatusChange: (value: boolean) => void
    }
> = ({
  currentRefinement,
  refine,
  hits,
  onResultClick,
  searchResults,
  handleSearchStatusChange,
  isSearchActive,
}) => {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = useState<number>()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (typeof searchResults?.query !== 'string') {
      return
    }

    tracking.trackSearch({
      'search-source': 'global-product',
      'search-count': searchResults.nbHits,
      'search-term': searchResults.query,
    })
  }, [searchResults?.query, searchResults?.nbHits])

  const inputChangeHandler = (event: any) => {
    refine(event.currentTarget.value)

    if (!open) setOpen(true)
  }

  return (
    <>
      {/* This dummy form should come before the actual search input.
    The idea is that the browser will target this dummy form for autofill instead of the actual search field. */}
      <FakeForm>
        <form>
          <input
            type="password"
            name="fakepassword"
            autoComplete="new-password"
          />
        </form>
      </FakeForm>
      <AnimatePresence>
        <StyledMotionDiv
          isSearchActive={isSearchActive}
          animate={{
            boxShadow: ` 0 0 0 100vmax  ${isSearchActive ? dimColor : 'rgba(0,0,0,0)'} `,
          }}
        >
          <AutocompleteWrapper
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
              onKeyDownSearch(e, setSelectedIndex, hits, router, selectedIndex)
            }
          >
            <SearchBox
              type="search"
              value={currentRefinement}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                inputChangeHandler(event)
              }
              placeholder={t('common:header.find_your_item_short')}
              onFocus={() => {
                handleSearchStatusChange(true)
              }}
              onBlur={() => {
                if (!Boolean(currentRefinement.length)) {
                  handleSearchStatusChange(false)
                }
              }}
              autoComplete="off"
              autoCorrect="off"
              role="searchbox"
            />

            <SearchButton>
              <SearchIcon />
            </SearchButton>

            <ResultList
              isSearchActive={isSearchActive}
              {...{ currentRefinement, refine, hits, selectedIndex }}
              onResultClick={onResultClick}
              setIsSearchActive={handleSearchStatusChange}
            />
          </AutocompleteWrapper>
        </StyledMotionDiv>
      </AnimatePresence>
    </>
  )
}

export default Autocomplete

const onKeyDownSearch = (
  event: React.KeyboardEvent<HTMLDivElement>,
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  hits: Array<any>,
  router: NextRouter,
  selectedIndex: number | undefined,
) => {
  if (!hits.length) {
    if (selectedIndex !== undefined) {
      setSelectedIndex(undefined)
    }
    return
  }

  if (event.keyCode === 38) {
    setSelectedIndex((current) => {
      if (current === undefined) return hits.length - 1

      if (current <= 0) return current

      return current - 1
    })
  } else if (event.keyCode === 40) {
    setSelectedIndex((current) => {
      if (current === undefined) return 0

      if (current >= hits.length - 1) return current

      return current + 1
    })
  } else if (event.keyCode === 13) {
    if (selectedIndex !== undefined) {
      router.push(`/item/${hits[selectedIndex].slug}`)
    }
  }
}
