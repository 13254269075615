import { storyblokEditable } from '@storyblok/react'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import Text from '@/style/components/LegacyText'
import { media } from '@/style/helpers'
import { ReviewsItemStoryblok } from '@/types/storyblok-component-types'
import DefaultIcon from './assets/profile_avatar.svg'

export default function ReviewsItem({ blok }: { blok: ReviewsItemStoryblok }) {
  return (
    <Wrapper {...storyblokEditable(blok)}>
      <TextWrapper
        variants={{
          enter: (direction: number) => ({
            left: direction ? -50 : 50,
          }),
          center: () => ({
            left: 0,
          }),
          exit: (direction: number) => ({
            left: direction ? -50 : 50,
          }),
        }}
        initial="enter"
        animate="center"
        exit="exit"
      >
        <RichTextWrapper>
          <StoryblokRichText document={blok.rating} />
        </RichTextWrapper>
      </TextWrapper>
      <UserWrapper>
        <DefaultIcon className="img" />
        <MotionSmText
          as={'p'}
          variants={{
            enter: {
              scale: 0,
            },
            center: {
              scale: 1,
            },
            exit: {
              scale: 0,
            },
          }}
          initial="enter"
          animate="center"
          exit="exit"
        >
          {blok.username}
        </MotionSmText>
      </UserWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const TextWrapper = styled(motion.div)`
  position: relative;
  max-width: 100%;
  overflow: auto;

  ${media.md} {
    max-width: 80%;
  }

  i {
    font-style: italic;
  }
`

const UserWrapper = styled.div`
  margin-top: 0.5rem;

  .img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.25rem;
  }
`

const MotionSmText: any = motion(Text.sm)

const RichTextWrapper = styled(RichTextParagraphStyles)`
  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  color: #ffffff !important;
`
